
import momentTz from 'moment-timezone'

// Collection of common manipulation functions
export function acronym(name) {
  if (!name)
    return ''
  const words = name.split(' ')
  return words.map(w => w.substr(0, 1)).join('').toUpperCase()
}

export function validUsername(username) {
  if (!username)
    return false
  return username.match(/^[a-zA-Z0-9-_]{3,255}$/)
}

// RFC 5322 (regex source: https://emailregex.com)
export function validEmail(email) {
  if (!email)
    return false
  return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

// TODO: Harmonise against dial code logic in customer-central and accounts-service
// Present logic harmonises against global-utils (our own spefication)
export function validContact(contact) {
  if (!contact)
    return false
  return contact.match(/^[0-9\-().+ ]{7,20}$/)
}

// TODO: Harmonise with Accounts Service documentation for latest Password Policy
export function validPassword(pwd) {
  if (!pwd)
    return false
  return pwd.match(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/)
}

export function startOfDay(date) {
  const d = date instanceof Date ? date : new Date(date)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export function endOfDay(date) {
  const d = date instanceof Date ? date : new Date(date)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)
}
let timezoneCache = {}
export function getListOfTimezones(){
  if(Object.keys(timezoneCache).length !== 0) return  timezoneCache
  timezoneCache =momentTz.tz.names().reduce((prev, current)=>{
    let timezone = momentTz.tz(current)
    return {
      ...prev, 
      [current]: { 
        zString: timezone.format("Z"),
        offset: timezone.utcOffset(),
        name: current
      }
    }
  }, {})
  return timezoneCache
}