import { useState, useContext } from 'react'
import { logout, getAccountsURL } from '../../api/accounts'
import { AccountsContext } from '../AccountsContextProvider'
import {
  useMediaURL,
} from '../../api/media'
import {
  Avatar,
  Box,
  Button,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const About = styled(Box)(({ theme }) => ({
  width:         theme.spacing(36.5),
  padding:       theme.spacing(2),
  display:       'flex',
  flexDirection: 'column',
  alignItems:    'center',
  textAlign:     'center',
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  width:           theme.spacing(32.5),
  height:          theme.spacing(6),
  borderRadius:    theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  position:        'relative',
}))

function go(pathname) {
  const url = getAccountsURL()
  url.pathname = pathname
  window.open(url, 'plex-accounts')
}

function Accounts() {
  const { user, company } = useContext(AccountsContext)
  const [ userAnchor,    setUserAnchor    ] = useState(null)
  const [ companyAnchor, setCompanyAnchor ] = useState(null)
  const userAvatarSrc    = useMediaURL(user    ? user.avatar    : '', 'thumb')
  const companyAvatarSrc = useMediaURL(company ? company.avatar : '', 'preview')

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      { company?.avatar ?
        <Tooltip title={company.name}>
          <ImageContainer>
            <img
              src={companyAvatarSrc}
              alt={company.name}
              placeholder='empty'
              style={{ 
                objectFit: 'contain', 
                borderRadius: 16, 
                width: '100%', 
                height: '100%' 
              }}
              onClick={e => { setCompanyAnchor(e.currentTarget) }} />
          </ImageContainer>
        </Tooltip>
      : <Button sx={{ textTransform: 'none' }}
          onClick={e => { setCompanyAnchor(e.currentTarget) }}>
          {company?.name}
        </Button>
      }
      { user &&
        <Tooltip title={user.name}>
          <Avatar
            src={userAvatarSrc}
            alt={user.name}
            sx={{ width: 48, height: 48 }}
            onClick={e => { setUserAnchor(e.currentTarget) }}>
            {user.name?.charAt(0)}
          </Avatar>
        </Tooltip>
      }
      <Popover
        open={Boolean(companyAnchor)}
        anchorEl={companyAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => { setCompanyAnchor(null) }}>
        <About>
          { company && <>
            { company.avatar &&
              <ImageContainer>
                <img
                  src={companyAvatarSrc}
                  alt='Company Logo'
                  style={{ 
                    objectFit: 'contain',
                    width: '100%', 
                    height: '100%' 
                  }}
                  placeholder='empty'
                />
              </ImageContainer>
            }
            <Typography variant='h6'>{company.name}</Typography>
            <Typography variant='body2'>{company.address}</Typography>
            <Typography variant='body2'>{company.country}</Typography>
            <Button onClick={() => { go('/general/company') }}>
              My Company
            </Button>
          </> }
          <br />
          <Button onClick={() => { go('/apps/all') }}>
            Manage Subscriptions
          </Button>
        </About>
      </Popover>
      <Popover
        open={Boolean(userAnchor)}
        anchorEl={userAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => { setUserAnchor(null) }}>
        <About>
          { user && <>
            <Avatar
              src={userAvatarSrc}
              alt={user.name}
              sx={{ width: 100, height: 100 }}>
              {user.name?.charAt(0)}
            </Avatar>
            <Typography variant='h6'>{user.name}</Typography>
            <Typography variant='body2'>{user.designation}</Typography>
            <br />
            <Typography variant='body2'>{user.email}</Typography>
          </> }
          <Button onClick={logout}>
            Logout
          </Button>
          <Button onClick={() => { go('/general/profile') }}>
            My Profile
          </Button>
        </About>
      </Popover>
    </Box>
  )
}

export default Accounts
