import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import { history } from '../utils/store'

import {
  Container,
  Typography
} from '@mui/material'

import userManager from '../utils/user-manager'

const Callback = ({user}) => {

  const successCallback = async () => {
    if(user?.state?.path){
      history.push(user?.state?.path)
    }else{
      history.push('/general/profile')
    }
  }

  const errorCallback = async err => {
    console.log('Error callback: ', err)
    await userManager.signinRedirect({})
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <Container style={{ paddingTop: '146px' }} maxWidth='lg'>
        <Typography style={{ width: '100%' }}>Redirecting...</Typography>
      </Container>
    </CallbackComponent>
  )
}

export default connect((state)=>{
  return {
    user: state?.oidc?.user
  }
}, null)(Callback)
