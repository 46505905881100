import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import userManager from '../../../utils/user-manager'
import {
  useMyUserId,
  useChangePasswordMutation,
} from '../../../api/accounts'
import { validPassword } from '../../../utils'
import {
  Button,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  container: {
    display:       'flex',
    flexDirection: 'column',
    alignItems:    'flex-start',
    rowGap:        theme.spacing(2),
  },
})

const Password = ({ classes }) => {

  const user_id = useMyUserId()
  const [ changePassword ] = useChangePasswordMutation()

  const location = useLocation()
  const [ showRedirect, setShowRedirect ] = useState(false)
  
  const [ currPwd, setCurrPwd ] = useState('')
  const [ newPwd,  setNewPwd  ] = useState('')
  const [ confPwd, setConfPwd ] = useState('')
  
  const [ error, setError ] = useState({ type: '', message: '' })

  const params = new URLSearchParams(location.search)
  const redirectUri = params.get('redirectUri')
  const forcePasswordChange = params.get('force_password_change')

  function handleSubmit() {
    if (!currPwd)
      return setError({ type: 'currPwd', message: 'Current password is empty' })
    if (!newPwd)
      return setError({ type: 'newPwd', message: 'New password is empty' })
    if (!confPwd)
      return setError({ type: 'confPwd', message: 'Confirm new password is empty' })
    if (newPwd !== confPwd)
      return setError({ type: 'confPwd', message: 'Both new passwords do not match' })
    clearError()

    changePassword({
      user_id,
      old_password: currPwd,
      new_password: newPwd,
    })
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('Failed to change password', fulfilled)
        toast.warn(`Failed to change password: ${fulfilled.data?.users || fulfilled.data}`)
        setError({ type: 'currPwd', message: fulfilled.data?.users || fulfilled.data })
      } else {
        if (redirectUri) {
          setShowRedirect(true)
          return
        }
        setCurrPwd('')
        setNewPwd('')
        setConfPwd('')
        toast.success('Password updated sucessfully')
      }
    }).then(() => {
      userManager.signinRedirect({ data: { path: '/' }})
    })
    .catch(rejected => {
      console.log('Error changing passwords', rejected)
      toast.error('Error changing passwords')
    })
  }

  function clearError() {
    setError({ type: '', message: '' })
  }

  function handleChangeCurrPwd(evt) {
    const val = evt.target.value
    setCurrPwd(val)

    if (val.length === 0)
      setError({ type: 'currPwd', message: 'Current password cannot be empty' })
    else
      clearError()
  }

  function handleChangeNewPwd(evt) {
    const val = evt.target.value
    setNewPwd(val)

    if (val.length === 0) 
      setError({ type: 'newPwd', message: 'New password cannot be empty' })
    else if (!validPassword(val))
      setError({ type: 'newPwd', message: 'New password must have at least 8 characters, with at least one alphabet and one digit' })
    else
      clearError()
  }

  function handleChangeConfPwd(evt) {
    const val = evt.target.value
    setConfPwd(val)

    if (val !== newPwd)
      setError({ type: 'confPwd', message: 'Both new passwords must match' })
    else
      clearError()
  }

  const forceChangeWarning = <Typography
    variant='body1'
    color='error'
  >
    As this is your first login, please change the temporary password to your private secure password.

  </Typography>

  const forceChangeRedirectMessaage = <Typography
    variant='body1'
    color='primary'
  >
    Thank you for changing your password. Click <a href={redirectUri}>here</a> to proceed
to your original destination.
  </Typography>

  const currPwdTextField = <TextField
    label='Current Password'
    variant='outlined'
    type='password'
    fullWidth
    value={currPwd}
    onChange={handleChangeCurrPwd}
    error={error.type === 'currPwd'}
    helperText={error.type === 'currPwd' ? error.message : ''}
  />

  const newPwdTextField = <TextField
    label='New Password'
    variant='outlined'
    type='password'
    fullWidth
    value={newPwd}
    onChange={handleChangeNewPwd}
    error={error.type === 'newPwd'}
    helperText={error.type === 'newPwd' ? error.message :
      validPassword(newPwd) ? 'Strong Password' :
      newPwd.length > 0 ?
      'Invalid Password (must have at least 8 characters, with at least one alphabet and one digit)' :
      ''}
  />

  const confPwdTextField = <TextField
    label='Confirm New Password'
    variant='outlined'
    type='password'
    fullWidth
    value={confPwd}
    onChange={handleChangeConfPwd}
    error={error.type === 'confPwd'}
    helperText={error.type === 'confPwd' ? error.message : ''}
  />

  const actionButton = <Button
    variant='contained'
    color='primary'
    onClick={handleSubmit}>
    Change Password
  </Button>

  return (
    <Container className={classes.container}>
      { showRedirect ? forceChangeRedirectMessaage : ''}
      { forcePasswordChange === 'true' && !showRedirect ? forceChangeWarning : ''}
      { currPwdTextField }
      { newPwdTextField }
      { confPwdTextField }
      { actionButton }
    </Container>
  )
}

export default withStyles(styles)(Password)