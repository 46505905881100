import {
  Box,
  Typography,
} from '@mui/material'

function History() {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography>Transaction History is not enabled for your account.</Typography>
    </Box>
  )
}

export default History
