import { useMediaURL } from '../api/media'
import { acronym } from '../utils'
import {
  Avatar,
  Typography,
} from '@mui/material'
import {
  Person,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  clickable: {
    cursor: 'pointer',
  },
  avatarFullscreen: {
    width:      theme.spacing(48),
    height:     theme.spacing(48),
    background: theme.palette.bluegrey.main,
  },
  avatarPreview: {
    width:      theme.spacing(18),
    height:     theme.spacing(18),
    background: theme.palette.bluegrey.main,
  },
  avatarThumb: {
    width:      theme.spacing(8),
    height:     theme.spacing(8),
    background: theme.palette.bluegrey.main,
  },
  img: {
    width:  '100%',
    height: '100%',
    objectFit: 'cover',
  },
  person: {
    width:  '50%',
    height: '50%',
  },
})

function UserAvatar({ classes, preview, user, size, onClick }) {
  const src = useMediaURL(user?.avatar, size)

  function getSizeClass() {
    switch (size) {
      case 'thumb':
        return classes.avatarThumb
      case 'preview':
        return classes.avatarPreview
      case 'fullscreen':
        return classes.avatarFullscreen
      default:
        return classes.avatarThumb
    }
  }
  return (
    <Avatar className={`${getSizeClass()} ${onClick ? classes.clickable : null}`} onClick={onClick}>
      { !user ?
        <Person className={classes.person} />
      : preview ?
        <img src={preview} alt={user.username} className={classes.img} />
      : user.avatar ?
        <img src={src} alt={user.username} className={classes.img} />
      :
        <Typography variant={size === 'thumb' ? 'body1' : 'h3'}>{ acronym(user.name) }</Typography>
      }
    </Avatar>
  )
}

export default withStyles(styles)(UserAvatar)
