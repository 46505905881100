import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'

import './DrawerContent.css'

import logo from '../../assets/img/garuda-plex-logo.png'

const LIST_NAVS = {
  GENERAL: [
    { name: 'Profile',   url: '/general/profile'   },
    { name: 'Company',   url: '/general/company'   },
    { name: 'Team',      url: '/general/team'      },
    { name: 'Developer', url: '/general/developer' },
  ],
  SECURITY: [
    { name: 'Password',  url: '/security/password' },
    // { name: 'Two Factor Authentication', url: '/security/2fa' },
    { name: 'Sessions',  url: '/security/sessions' },
    { name: 'Audit Log', url: '/security/audit'    },
  ],
  APPS: [
    { name: 'Applications',        url: '/apps/all'     },
    { name: 'Roles',               url: '/apps/roles'   },
    { name: 'Usage',               url: '/apps/usage'   },
    { name: 'Payment Methods',     url: '/apps/payment' },
    { name: 'Transaction History', url: '/apps/history' },
  ]
}

const DrawerContent = ({ classes, push }) => {
  const [activeURL, setActiveURL] = useState('')

  useEffect(() => {
    const handleActiveNav = () => setActiveURL(window.location.pathname)
    handleActiveNav()
  })

  const handleGoTo = (url = '') => {
    push(url)
    setActiveURL(url)
  }

  return (
    <div className='DrawerContent'>
      <div id='DrawerContent-Header' className={classes.toolbar}>
        <img src={logo} alt='' />
      </div>
      <Divider />
      <Typography className={classes.subHeader}>General</Typography>
      <List>
        {LIST_NAVS.GENERAL.map(({ name, url }, index) => (
          <ListItem button key={url} onClick={() => handleGoTo(url)} className={url === activeURL ? 'Active-Nav' : ''}>
            <ListItemText primary={name} className={classes.listItemText} />
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subHeader}>Security</Typography>
      <List>
        {LIST_NAVS.SECURITY.map(({ name, url }, index) => (
          <ListItem button key={url} onClick={() => handleGoTo(url)} className={url === activeURL ? 'Active-Nav' : ''}>
            <ListItemText primary={name} className={classes.listItemText} />
          </ListItem>
        ))}
      </List>
      <Typography className={classes.subHeader}>Apps</Typography>
      <List>
        {LIST_NAVS.APPS.map(({ name, url }, index) => (
          <ListItem button key={url} onClick={() => handleGoTo(url)} className={url === activeURL ? 'Active-Nav' : ''}>
            <ListItemText primary={name} className={classes.listItemText} />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default connect(null, { push })(DrawerContent)
