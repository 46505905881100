import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  title: {
    backgroundColor: theme.palette.secondary.main,
  },
})

function AreYouSureDialog({ classes, open, onClose, title, message, action, onOK }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      fullWidth
      maxWidth='sm'>
      <DialogTitle className={classes.title}>
        { title || 'Confirmation' }
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          { message || 'Are you sure?' }
        </Typography>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <Button variant='outlined'
          onClick={() => onOK(false)}>
          Cancel
        </Button>
        <Button variant='contained' color='secondary'
          onClick={() => onOK(true)}>
          { action || 'OK' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AreYouSureDialog)
