import { useEffect, useState } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@mui/material'
import { Menu } from '@mui/icons-material'
import Accounts from './Accounts'

const Navbar = ({ classes, path, handleDrawerToggle }) => {
  const [ title, setTitle ] = useState('')

  useEffect(() => {
    function changeTitle(title) {
      setTitle(title)
      document.title = 'Accounts | ' + title
    }

    switch (path) {
      // General
      case '/general':           changeTitle('General Section'); break
      case '/general/profile':   changeTitle('My Profile'); break
      case '/general/company':   changeTitle('My Company'); break
      case '/general/team':      changeTitle('My Team'); break
      case '/general/developer': changeTitle('Developer'); break
      // Security
      case '/security':          changeTitle('Security Section'); break
      case '/security/password': changeTitle('Change Password'); break
      // case '/security/2fa':   changeTitle('Set Up Two Factor Authentication'); break
      case '/security/sessions': changeTitle('Login Sessions'); break
      case '/security/audit':    changeTitle('Audit Log'); break
      // Apps
      case '/apps':              changeTitle('Apps Section'); break
      case '/apps/all':          changeTitle('Applications'); break
      case '/apps/roles':        changeTitle('Roles'); break
      case '/apps/usage':        changeTitle('Metered Usage'); break
      case '/apps/payment':      changeTitle('Payment Methods'); break
      case '/apps/history':      changeTitle('Transaction History'); break
      // Specific Apps
      case '/apps/facilities':   changeTitle('Facilities 4.0'); break
      // default
      default:                   changeTitle('')
    }
  }, [path])

  return (
    <div>
      <AppBar className={classes.appBar} position='fixed'>
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            size='large'
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <Menu />
          </IconButton>
          <Typography variant='h6' className={classes.title}>{title}</Typography>
          <Accounts />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
