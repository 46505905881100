import { useState } from 'react'
import { toast } from 'react-toastify'
import { useUploadCompanyAvatarMutation } from '../../../api/accounts'
import { TopXCloseButton, CompanyAvatar } from '../../../components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material'
import {
  Portrait,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  uploadDisabled: {
    color: theme.palette.common.white,
  },
  uploadEnabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
})

const CompanyAvatarDialog = ({classes, company, open, onClose}) => {
  const [ uploadEnabled, setUploadEnabled ] = useState(false)
  const [ image,         setImage         ] = useState(null)
  const [ previewUrl,    setPreviewUrl    ] = useState(null)
  const [ uploadAvatar,  { isLoading }    ] = useUploadCompanyAvatarMutation()

  function handleOnClose() {
    onClose()
    setPreviewUrl(null)
    setUploadEnabled(false)
  }

  function handleUpload() {
    uploadAvatar({ company_id: company.company_id, file: image })
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status === 'success') {
          toast.success('Company logo updated')
          onClose()
        } else {
          toast.warn('Failed to update company logo')
          console.log('Failed to update company logo', fulfilled)
          setUploadEnabled(true)
        }
      })
      .catch(rejected => {
        toast.error('Error updating company logo')
        console.log('Error updating company logo', rejected)
        setUploadEnabled(true)
      })
  }

  function handleFileInput(e) {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setImage(file)
      setPreviewUrl(reader.result)
    }

    reader.readAsDataURL(file)
    setUploadEnabled(true)
  }

  return(
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth
      maxWidth='sm'>
      <TopXCloseButton onClick={handleOnClose} />
      <DialogTitle>
        Company Logo
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          spacing={1}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <CompanyAvatar preview={previewUrl} company={company} size='fullscreen' />
          </Grid>
          <Grid item>
            <Button
              component='label'
              variant='contained'
              startIcon={<Portrait />}>
              Select Logo Image
              <input hidden type='file' accept='image/png, image/gif, image/jpeg'
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!uploadEnabled || isLoading}
          className={uploadEnabled ? classes.uploadEnabled : classes.uploadDisabled}
          onClick={handleUpload}>
          { isLoading ? 'Uploading...' : 'Upload' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(CompanyAvatarDialog)
