import { useState } from 'react'

import Navbar        from '../Navbar'
import DrawerContent from './DrawerContent'
import useStyles     from './useStyles'
import {
  Drawer,
  Hidden,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

const LeftNav = ({ window, children, path }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.LeftNav}>
      <Navbar
        path={path}
        classes={classes}
        handleDrawerToggle={handleDrawerToggle} />
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <DrawerContent classes={classes} />
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation='css'>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant='permanent'
            open
          >
            <DrawerContent classes={classes} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  )
}

export default LeftNav
