import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useMyUserId,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from '../../../api/accounts'
import { countries } from 'countries-list'
import { validEmail, validContact } from '../../../utils'
import UserAvatar from '../../../components/UserAvatar'
import AvatarDialog from './UserAvatarDialog'
import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  gridContainer: {
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  field: {
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1),
  },
})

function Profile({ classes }) {
  const user_id = useMyUserId()
  const {
    data:      userData,
    error:     userError,
    isLoading: userLoading
  } = useGetUserByIdQuery({user_id})

  if (!userLoading) {
    if (userError) {
      console.log('Error loading user:', userError)
    } else if (userData.status !== 'success') {
      console.log('Failed loading user:', userData)
    }
  }

  const [ updateUser, updateResult ] = useUpdateUserMutation()

  const [ user,     setUser     ] = useState({})
  const [ editable, setEditable ] = useState(false)
  const [ avOpen,   setAvOpen   ] = useState(false)

  useEffect(() => {
    setUser(prevUser => {
      return userData?.data?.user || {}
    })
  }, [userData])

  function handleSubmitUpdateProfile() {
    const newUser = {
      user_id:     user.user_id,
      name:        user.name,
      email:       user.email,
      contact:     user.contact,
      address:     user.address,
      country:     user.country,
      designation: user.designation
    }

    updateUser(newUser)
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      console.log('update result', updateResult)

      if (fulfilled.status === 'success') {
        toast.success('Profile updated')
        setEditable(false)
      } else {
        toast.warn('Failed to update profile')
      }
    })
    .catch(rejected => {
      console.log('udpate rejected', rejected)
      toast.error('Error updating profile. Try again later')
    })
  }

  function checkEmail(email) {
    if (email === '' || validEmail(email))
      return [ true, '' ]
    else
      return [ false, 'Invalid email format' ]
  }

  function checkContact(contact) {
    // TODO: Harmonise against dial code logic in customer-central and accounts-service
    // Present logic harmonises against global-utils (our own spefication)
    if (contact === '' || validContact(contact))
      return [ true, '' ]
    else
      return [ false, 'Contact must be only digits, dash, dot, plus, and brackets, between 7 and 20 characters' ]
  }

  const fields = [
    ['username',    'Username', true, true   ],
    ['name',        'Full Name'              ],
    ['email',       'Email',    checkEmail   ],
    ['contact',     'Contact',  checkContact ],
    ['address',     'Address'                ],
    ['country',     'Country'                ],
    ['designation', 'Designation'            ]
  ]

  function readOnlyFields() {
    return (<>{
      fields.map(([key = '', label = '']) => (
        <div className={classes.field} key={key}>
          <Typography variant='overline' style={{ lineHeight: '1em' }}>
            {label}
          </Typography>
          <Typography variant='h5'>
            {user[key] || '-'}
          </Typography>
        </div>
      ))
    }</>)
  }

  function editableFields() {

    const onChange = (e, key) => {
      const newUser = { ...user }
      newUser[key] = e.target.value
      setUser(newUser)
    }

    return (
      <>
        { fields.map(([key = '', label = '', isValid = () => [ true, '' ], disabled = false]) => {
          if (disabled)
            return <div className={classes.field} key={key}>
              <Typography variant='overline' style={{ lineHeight: '1em' }}>
                {label}
              </Typography>
              <Typography variant='h5'>
                {user[key] || '-'}
              </Typography>
            </div>
          else if (key === 'country')
            return <TextField select fullWidth
              className={classes.textField}
              key={key}
              label={label}
              variant='outlined'
              value={user[key] || ''}
              onChange={e => onChange(e, key)}>
              { Object.keys(countries).map(cn => (
                <MenuItem key={cn} value={countries[cn].name}>{countries[cn].name}</MenuItem>
              )) }
            </TextField>
          else {
            const [ valid, errorMsg ] = isValid(user[key])
            return <TextField fullWidth
              className={classes.textField}
              key={key}
              label={label}
              variant='outlined'
              type='text'
              value={user[key] || ''}
              onChange={e => onChange(e, key)}
              error={!valid}
              helperText={errorMsg}
            />
          }
        }) }
      </>
    )
  }

  const editButton = <Button
    onClick={() => setEditable(true)}
    variant='contained'
    color='primary'>
    Edit Profile
  </Button>

  const cancelButton = <Button
    onClick={() => setEditable(false)}
    variant='outlined'
    color='primary'>
    Cancel
  </Button>

  const saveButton = <Button
    onClick={handleSubmitUpdateProfile}
    variant='contained'
    color='primary'>
    Save
  </Button>

  return (
    <Container>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item md={3}>
          <Grid item className={classes.field}>
            <UserAvatar
              user={user}
              size={'preview'}
              onClick={() => { setAvOpen(true) }}
            />
          </Grid>
          <Grid item className={classes.field}>
            <Button
              onClick={() => { setAvOpen(true) }}
              variant='contained'
              color='primary'>
              Upload Avatar
            </Button>
          </Grid>
        </Grid>
        <Grid item md={9}>
          { editable ?
            <>
              { editableFields() }
              { cancelButton } { saveButton }
            </>
          : <>
              { readOnlyFields() }
              <div className={classes.field}>
                { editButton }
              </div>
            </>
          }
        </Grid>
      </Grid>
      <AvatarDialog
        open={avOpen}
        onClose={() => setAvOpen(false)}
        user={user}
      />
    </Container>
  )
}

export default withStyles(styles)(Profile)
