import { standardHeaders } from './api-utils'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const IDENTITY_API = new URL(process.env.REACT_APP_IDENTIY_API).href

export const identityApi = createApi({
  reducerPath: 'identityApi',
  baseQuery: fetchBaseQuery({
    baseUrl: IDENTITY_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Clients'],
  endpoints: (builder) => ({

    getClients: builder.query({
      query: () => `/clients`,
      providesTags: ['Clients']
    }),

    createClient: builder.mutation({
      query: (body) => ({
        url: '/clients',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Clients']
    }),

    deleteClient: builder.mutation({
      query: (client_id) => ({
        url: `/clients/${client_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Clients']
    }),

  })
})

export const {
  useGetClientsQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
} = identityApi
