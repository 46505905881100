import { useMediaURL } from '../api/media'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  avatar: {
    display: 'flex',
    direction: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
  avatarFullscreen: {
    padding:      theme.spacing(1),
    width:        theme.spacing(48),
    height:       theme.spacing(8.9),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.bluegrey.main}`,
  },
  avatarPreview: {
    padding:      theme.spacing(1),
    width:        theme.spacing(48),
    height:       theme.spacing(8.9),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.bluegrey.main}`,
  },
  avatarThumb: {
    padding:      theme.spacing(1),
    width:        theme.spacing(48),
    height:       theme.spacing(8.9),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.bluegrey.main}`,
  },
  img: {
    maxWidth:  '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  tg: {
    color: theme.palette.bluegrey.contrastText,
  }
})

function CompanyAvatar({ classes, preview, company, size, onClick }) {
  const src = useMediaURL(company.avatar, size)

  function getSizeClass() {
    switch (size) {
      case 'thumb':
        return classes.avatarThumb
      case 'preview':
        return classes.avatarPreview
      case 'fullscreen':
        return classes.avatarFullscreen
      default:
        return classes.avatarThumb
    }
  }

  return (
    <div className={`${getSizeClass()} ${onClick ? classes.clickable : null} ${classes.avatar}`} onClick={onClick}>
      { !company ?
        null
      : preview ?
        <img src={preview} alt={company.name} className={classes.img} />
      : company.avatar ?
        <img src={src} alt={company.name} className={classes.img} />
      :
        <Typography variant='body1' className={classes.tg}>{ company.name }</Typography>
      }
    </div>
  )
}

export default withStyles(styles)(CompanyAvatar)
