import { validUsername, validEmail, validContact, validPassword } from '../../../utils'
import { countries } from 'countries-list'
import {
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  field: {
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1),
  },
})

function UserFields({ classes, isNewUser, canUpdate, user, setUser }) {

  function checkUsername(username) {
    if (username === '' || validUsername(username))
      return [ true, '' ]
    return [ false, 'Username can only be alphabet (case sensitive), digits, dash and underscore, between 3 and 255 characters' ]
  }

  function checkEmail(email) {
    if (email === '' || validEmail(email))
      return [ true, '' ]
    return [ false, 'Invalid email format' ]
  }

  function checkContact(contact) {
    if (contact === '' || validContact(contact))
      return [ true, '' ]
    return [ false, 'Contact must be only digits, dash, dot, plus, and brackets, between 7 and 20 characters' ]
  }

  function checkPassword(pwd) {
    if (pwd === '')
      return [ false, 'Set a new temporary password for this user' ]
    if (validPassword(pwd))
      return [ true, '' ]
    return [ false, 'New password must have at least 8 characters, with at least one alphabet and one digit' ]
  }

  const fields = [
    ['username', 'Username', checkUsername],
    ['name', 'Full Name'],
    ['email', 'Email', checkEmail],
    ['contact', 'Contact', checkContact],
    ['address', 'Address'],
    ['country', 'Country'],
    ['designation', 'Designation'],
  ]

  if (!user)
    return null

  if (!canUpdate) {
    return (<>{
      fields.map(([key = '', label = '']) => (
        <div className={classes.field} key={key}>
          <Typography variant='overline' style={{ lineHeight: '1em' }}>
            {label}
          </Typography>
          <Typography variant='h5'>
            {user[key] || '-'}
          </Typography>
        </div>
      ))
    }</>)
  }

  const onChange = (e, key) => {
    const newUser = { ...user }
    newUser[key] = e.target.value
    setUser(newUser)
  }

  let passwordField = null
  if (isNewUser) {
    const [ valid, errorMsg ] = checkPassword(user.password)
    passwordField = <TextField fullWidth
      className={classes.textField}
      key='password'
      label='Password'
      variant='outlined'
      type='password'
      value={user.password}
      onChange={e => onChange(e, 'password')}
      error={!valid}
      helperText={errorMsg}
    />
  }

  return (
    <>
      { fields.map(([key = '', label = '', isValid = () => [ true, '' ]]) => {
        if (!isNewUser && key === 'username')
          return <div className={classes.field} key={key}>
            <Typography variant='overline' style={{ lineHeight: '1em' }}>
              {label}
            </Typography>
            <Typography variant='h5'>
              {user[key] || '-'}
            </Typography>
          </div>
        else if (key === 'country')
          return <TextField select fullWidth
            className={classes.textField}
            key={key}
            label={label}
            variant='outlined'
            value={user[key] || ''}
            onChange={e => onChange(e, key)}>
            { Object.keys(countries).map(cn => (
              <MenuItem key={cn} value={countries[cn].name}>{countries[cn].name}</MenuItem>
            )) }
          </TextField>
        else {
          const [ valid, errorMsg ] = isValid(user[key])
          return <TextField fullWidth
            className={classes.textField}
            key={key}
            label={label}
            variant='outlined'
            type='text'
            value={user[key] || ''}
            onChange={e => onChange(e, key)}
            error={!valid}
            helperText={errorMsg}
          />
        }
      }) }
      { passwordField }
    </>
  )
}

export default withStyles(styles)(UserFields)
