import { startOfDay, endOfDay } from '../../../utils'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  History,
  CheckCircle,
  Update,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
})

// TODO: refactor later
function invoiceFrom(party) {
  return {
    'xero':    'Invoice from Garuda Robotics Pte. Ltd.',
    'xero-my': 'Invoice from Garuda Robotics (M) Sdn. Bhd.',
    'bundle':  'Invoiced through channel partners or included in your service bundle',
    'direct-payment': 'No invoice - direct payment',
  }[party] || `Unknown Invoice from ${party}`
}

function paymentFrom(method) {
  return {
    'bank-transfer': 'Interbank Transfer Payment (GIRO/FAST/TT)',
    'paynow':        'PayNow Payment',
    'duitnow':       'DuitNow Payment',
    'stripe':        'Payment via Stripe (Credit / Debit Card)',
    'antbuildz':     'Payment via Antbuildz',
    'not-required':  'Payment is not required',
  }[method] || `Unknown Payment Method ${method}`
}

function AppSubscriptionsTable({ classes, now, app, sortedSubs }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Period Start</TableCell>
            <TableCell>Period End</TableCell>
            <TableCell>Transaction Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { sortedSubs.filter(s => s.app === app.key).map(sub => (
            <TableRow key={sub._id}>
              <TableCell>
                { now < startOfDay(sub.start_date).getTime() ?
                    <Button disabled variant='outlined' startIcon={<Update />}>
                      Starting Later
                    </Button>
                  : now > endOfDay(sub.end_date).getTime() ?
                    <Button disabled variant='outlined' startIcon={<History />}>
                      Expired
                    </Button>
                  : 
                    <Button variant='contained' startIcon={<CheckCircle />}
                      color='secondary'>
                      Active
                    </Button>
                }
              </TableCell>
              <TableCell>
                { new Date(sub.start_date).toLocaleDateString() }
              </TableCell>
              <TableCell>
                { new Date(sub.end_date).toLocaleDateString() }
              </TableCell>
              <TableCell>
                { sub.offer_id && <>
                  <Typography variant='body1'>Promotional Offer:</Typography>
                  <Typography variant='body2'>{sub.offer_id}</Typography>
                  <br />
                </> }
                <Typography variant='body1'>{invoiceFrom(sub.invoice_method)}</Typography>
                <Typography variant='body2'>Invoice ID: {sub.invoice_id}</Typography>
                <br />
                <Typography variant='body1'>{paymentFrom(sub.payment_method)}</Typography>
                <Typography variant='body2'>Payment ID: {sub.payment_id}</Typography>
              </TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
    </TableContainer>
  ) 
}

export default withStyles(styles)(AppSubscriptionsTable)
