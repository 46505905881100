import { withStyles } from '@mui/styles'

const styles = theme => ({
  ctr: {
    display:         'flex',
    width:           '100vw',
    height:          '100vh',
    justifyContent:  'center',
    alignItems:      'center',
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.bluegrey.dark,
  },
})

function ScreenCenter({ classes, children }) {
  return (
    <div className={classes.ctr}>
      { children }
    </div>
  )
}

export default withStyles(styles)(ScreenCenter)
