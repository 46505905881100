import { useEffect, useState } from 'react'

import './Pagination.css'

const Pagination = ({
  rowsPerPageOptions = [5, 10, 25, 50, 100],
  rowsPerPage = 0,
  onPerPageChange = () => {},
  rowsLength = 0,
  page = 0,
  onPageChange = () => {}
}) => {
  const _rowsPerPage = Number(rowsPerPage) || rowsPerPageOptions[0]
  const [perPage, setPerPage] = useState(_rowsPerPage)
  const [isSelectingPerPage, setIsSelectingPerPage] = useState(false)
  const [selectedPage, setSelectedPage] = useState(page)

  useEffect(() => {
    setIsSelectingPerPage(false)
    onPerPageChange(perPage)
  }, [perPage, onPerPageChange])

  useEffect(() => {
    onPageChange(selectedPage)
  }, [selectedPage, onPageChange])

  const handleShowPerPageOption = () => {
    setIsSelectingPerPage(true)
    const paginationPerPagePopup = document.querySelector('.PaginationPerPagePopup')
    !isSelectingPerPage && paginationPerPagePopup.classList.add('show')
  }

  const handleSelectPerPage = (row = 0) => {
    setIsSelectingPerPage(true)
    setSelectedPage(0)
    setPerPage(row)
    const paginationPerPagePopup = document.querySelector('.PaginationPerPagePopup')
    paginationPerPagePopup.classList.remove('show')
  }

  const handleSelectPage = (page = 0) => {
    setSelectedPage(page)
  }

  return (
    <div className='Pagination'>
      <div
        className={`PaginationNext ${selectedPage >= Math.round(rowsLength / _rowsPerPage) - 1 ? 'disable' : ''}`}
        onClick={() => selectedPage < (Math.round(rowsLength / _rowsPerPage) - 1) && handleSelectPage(selectedPage + 1)}
      >
        <svg><path d='M0 0h24v24H0V0z' fill='none' /><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z' /></svg>
      </div>
      <div
        className={`PaginationPrev ${selectedPage <= 0 ? 'disable' : ''}`}
        onClick={() => selectedPage > 0 && handleSelectPage(selectedPage - 1)}
      >
        <svg><path d='M0 0h24v24H0V0z' fill='none' /><path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z' /></svg>
      </div>
      <div className='PaginationInfo'><p>{(page * _rowsPerPage) + 1} - {((page * _rowsPerPage) + _rowsPerPage) > rowsLength ? rowsLength : (page * _rowsPerPage) + _rowsPerPage} of {rowsLength}</p></div>
      <div className='PaginationPerPage' onClick={handleShowPerPageOption}>
        <div className='PaginationPerPagePopup'>
          {rowsPerPageOptions.map((row, i) => (
            <p
              key={i}
              className={row === _rowsPerPage ? 'active' : ''}
              onClick={() => handleSelectPerPage(row)}
            >
              {row}
            </p>
          ))}
        </div>
        <p>{perPage}</p>
        <svg><path d='M0 0h24v24H0V0z' fill='none' /><path d='M7 10l5 5 5-5H7z' /></svg>
      </div>
      <div className='PaginationRowsPerPage'><p>Rows per page:</p></div>
    </div>
  )
}

export default Pagination
