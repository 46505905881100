import { startOfDay, endOfDay } from '../../../utils'
import AppSubscriptionsTable from './AppSubscriptionsTable'
import {
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
  logo: {
    width: '100%',
  },
})

function AppDetails({ classes, app, sortedSubs, onBack }) {
  // TODO: Pricing
  const now = new Date().getTime()
  const subscribed = sortedSubs.some(sub =>
    sub.app === app.key &&
    now > startOfDay(sub.start_date).getTime() &&
    now < endOfDay(sub.end_date).getTime())

  return (
    <Grid container justifyContent='center' spacing={8} className={classes.root}>
      <Grid item xs={3}>
        <img alt={app.key} src={app.logo} className={classes.logo} />
      </Grid>
      <Grid item xs={9}>
        <Typography variant='h5'>About {app.name}</Typography>
        <Typography variant='body1'>{app.description}</Typography>
      </Grid>
      <div style={{ justifyContent: 'space-evenly' }}>
        <Button variant='outlined' onClick={onBack}>
          Back
        </Button> {
          subscribed ?
            <Button variant='contained' color='primary'
              onClick={() => window.open(app.link, '_blank')}>
              Launch
            </Button>
          :
            <Button variant='contained' color='primary'
              onClick={() => window.open('mailto:sales@garuda.io?subject=[acc] Subscribe / Renew ' +
                app.name)}>
              Subscribe
            </Button>
        } <Button variant='outlined' color='primary'
          onClick={() => window.open('mailto:sales@garuda.io?subject=[acc] Enquiry on ' + app.name)}>
          Contact Sales
        </Button>
      </div>
      { sortedSubs?.length > 0 &&
        <Grid item xs={12}>
          <Typography variant='h5'>Your Subscriptions</Typography>
          <AppSubscriptionsTable
            now={now}
            app={app}
            sortedSubs={sortedSubs}
          />
        </Grid>
      }
    </Grid>
  )
}

export default withStyles(styles)(AppDetails)
