import { useAccessToken } from './accounts'

const MEDIA_API = new URL(process.env.REACT_APP_MEDIA_API).href

export function useMediaURL(mediaId, size) {
  const access_token = useAccessToken()

  if (!['original', 'thumb', 'preview', 'fullscreen', 'upnorth'].includes(size))
    throw Error('Invalid size for Media:', size)

  // Temporary construct, specifically to feed <img /> tags which can't take headers
  // TODO: Solve the problem where this link doesn't create a filename for the download
  return MEDIA_API + '/m/' + mediaId + '/' + size + '?token=' + access_token
}

