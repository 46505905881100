import { Fragment } from 'react'
import { toast } from 'react-toastify'
import {
  useMyCompanyId,
  useActiveAppRolesQuery,
  useAppRolesSummaryQuery,
  useGetSubscriptionsQuery,
} from '../../../api/accounts'
import applications from '../../../utils/applications'
import AppRoleAndDesc from './AppRoleAndDesc'
import {
  Button,
  Grid,
  Typography,
} from '@mui/material'
import {
  ErrorOutline,
} from '@mui/icons-material'
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  title: {
    color: theme.palette.common.white,
  },
  logo: {
    width:      '200px',
    textAlign:  'center',
    paddingTop: '16px',
  },
  roleRow: {
    width: 'calc(100% - 260px)',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.bluegrey.main,
  },
})

function UserAppRoles({ classes, user, canUpdate, onUpdateRole, onReset, onSaveUpdate }) {
  const company_id = useMyCompanyId()
  const { data: rolesData,  isLoading: rolesLoading,  error: rolesError  } = useAppRolesSummaryQuery()
  const { data: activeData, isLoading: activeLoading, error: activeError } = useActiveAppRolesQuery()
  const { data: subData,    isLoading: subLoading,    error: subError    } = useGetSubscriptionsQuery({ company_id })
  if (!user)
    return null
  if (rolesLoading || activeLoading || subLoading)
    return null
  if (rolesError || activeError || subError) {
    console.log('Error loading app roles. rolesError', rolesError, 'activeError', activeError, 'subError', subError)
    toast.error('Error loading app roles')
    return null
  }
  if (rolesData.status !== 'success') {
    console.log('Failed loading app roles:', rolesData)
    toast.warning('Failed loading app roles')
    return null
  }
  if (subData.status !== 'success') {
    toast.warn('Failed loading subscriptions')
    console.log('Failed loading subscriptions:', subData)
    // continue
  }
  const activeRoles = activeData?.data?.app_roles  || []
  const allAppRoles = rolesData?.data?.app_roles   || []
  const subs        = subData?.data?.subscriptions || []
  const allAppKeys  = Object.keys(allAppRoles)
  const appsWithSub = ['account', ...allAppKeys.filter(k => subs.some(s => s.app === k))]

  // NOTE: Watch out, legacy user objects has no app_roles, need to add it in.
  return (<>
    <div className={classes.action}>
      <div className={classes.title}>
        <Typography variant='h6' style={{ lineHeight: 1 }}>
          Roles for {user.name}
        </Typography>
        <Typography variant='body2'>
          { canUpdate ? <>
            You can only change roles for apps you have subscribed to.
            Click "Update Roles" after you're done updating the role.
          </> : <>
            Only your company administrator(s) can update application roles.
          </> }
        </Typography>
      </div>
      { canUpdate && <>
        <Button variant='outlined'  
          onClick={() => onReset()}>
          Reset
        </Button>
        <Button variant='contained' color='primary'
          disabled={rolesLoading}
          onClick={() => onSaveUpdate(user.user_id)}>
          Update Roles
        </Button>
      </> }
    </div>
    <Grid container>
      { Object.keys(allAppRoles).map(appKey => <Fragment key={appKey}>
        <Grid item key={appKey} className={classes.logo}>
          <AppLogoAndName appKey={appKey}
            app={applications.find(app => app.key === appKey)} />
        </Grid>
        <Grid item className={classes.roleRow}>
          { appsWithSub.includes(appKey) ? (
              allAppRoles[appKey]?.length > 0 ?
                allAppRoles[appKey].map(role => {
                  const aar = activeRoles.find(a => a.app === appKey && a.role === role)
                  return <AppRoleAndDesc
                    key={role}
                    user={user}
                    role={role}
                    aar={aar}
                    appKey={appKey}
                    canUpdate={canUpdate}
                    onClick={() => onUpdateRole(user, appKey, role)}
                  />
                })
              : <ErrorDesc>
                  There are no roles configured for this application.
                </ErrorDesc>
            )
          : <ErrorDesc>
              App subscription not found.
            </ErrorDesc>
          }
        </Grid>
      </Fragment>) }
    </Grid>
  </>)
}

function ErrorDesc({ children }) {
  return (
    <div style={{ paddingTop: '16px', display: 'flex', alignItems: 'center', gap: '8px' }}>
      <ErrorOutline />
      <Typography variant='body2'>{children}</Typography>
    </div>
  )
}

function AppLogoAndName({ app, appKey }) {
  return (app ?
    <>
      <img src={app.logo} alt={appKey} style={{ width: '180px' }} />
      <br />
      <Typography variant='caption'>{ app.name }</Typography>
    </>
  :
    <Typography variant='body1'>Unknown app "{appKey}"</Typography>
  )
}

export default withStyles(styles)(UserAppRoles)
