import { createContext } from 'react'
import {
  useMyUserId,
  useMyCompanyId,
  useGetUserByIdQuery,
  useGetCompanyByIdQuery,
  useGetSubscriptionsQuery,
} from '../api/accounts'

export const AccountsContext = createContext({})

function AccountsContextProvider({ children }) {

  const company_id = useMyCompanyId()
  const user_id = useMyUserId()

  const { data: uData, isLoading: uLoading, error: uError } = useGetUserByIdQuery({ user_id }, { skip: !user_id })
  const { data: cData, isLoading: cLoading, error: cError } = useGetCompanyByIdQuery({ company_id }, { skip: !company_id })
  const { data: sData, isLoading: sLoading, error: sError } = useGetSubscriptionsQuery({ company_id }, { skip: !company_id })


  if (!uLoading) {
    if (uError) {
      console.log('Error loading user', uError)
    }
    else if (uData?.status !== 'success') {
      console.log('Failed loading user', uData)
    }
  }

  if (!cLoading) {
    if (cError) {
      console.log('Error loading company', cError)
    }
    else if (cData?.status !== 'success') {
      console.log('Failed loading company', cData)
    }
  }

  if (!sLoading) {
    if (sError) {
      console.log('Error loading subscriptions', sError)
    }
    else if (sData?.status !== 'success') {
      console.log('Failed loading subscriptions', sData)
    }
  }

  const now = Date.now()
  const subscriptions = sData?.data?.subscriptions
  const activeSubs    = subscriptions?.filter(s => (s.start_date < now && s.end_date > now))

  return (
    <AccountsContext.Provider value={{
      company_id,
      company:     cData?.data?.company || null,

      user_id,
      userLoading: uLoading,
      user:        uData?.data?.user || null,

      subscriptionsLoading: sLoading,
      subscriptions:        subscriptions || [],
      activeSubscriptions:  activeSubs    || [],
    }}>
      {children}
    </AccountsContext.Provider>
  )
}

export default AccountsContextProvider
