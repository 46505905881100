import TopXCloseButton from './TopXCloseButton'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material'

function WaitDialog({ msg, onClose = () => {} }) {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <CircularProgress />
          <Typography variant='body1' style={{ marginLeft: '16px' }}>
            { msg }
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  )
}


export default WaitDialog