import { useEffect } from 'react'

import {
  Card
} from '@mui/material'

import './Accordion.css'

export const AccordionTitle = ({ children }) => {
  return (
    <div className='Accordion-Title'>
      {children}
      <div className='Accordion-ChevronIcon'>
        <svg className='Accordion-ChevronIcon-ExpandMore'><path d='M24 24H0V0h24v24z' fill='none' opacity='.87' /><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' /></svg>
        <svg className='Accordion-ChevronIcon-ExpandLess'><path d='M0 0h24v24H0V0z' fill='none' /><path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z' /></svg>
      </div>
    </div>
  )
}

export const AccordionContent = ({ children }) => {
  return (
    <div className='Accordion-Content'>{children}</div>
  )
}

const Accordion = ({
  children,
  disabled = false,
  style = {},
  className = ''
}) => {
  useEffect(() => {
    const thisEl = document.querySelector('.Accordion')
    const childNodes = thisEl.parentElement.childNodes
    const accordions = []
    for (const childNode of childNodes) {
      if (childNode.classList.contains('Accordion')) accordions.push(childNode)
    }
    for (let i = 0; i < accordions.length; i++) {
      if (accordions[i] === accordions[0]) accordions[i].childNodes[0].classList.add('Accordion-First')
      else if (accordions[i] === accordions[accordions.length - 1]) accordions[i].childNodes[0].classList.add('Accordion-Last')
      else accordions[i].childNodes[0].classList.add('Accordion-Mid')
    }
  }, [])

  const handleSelectAccordion = ({ currentTarget }) => {
    const thisEl = document.querySelector('.Accordion')
    const childNodes = thisEl.parentElement.childNodes
    for (const childNode of childNodes) childNode.childNodes[0].classList.remove('Accordion-Active')

    currentTarget.classList.add('Accordion-Active')
  }

  return (
    <div className={`Accordion ${className}`} style={{ ...style }}>
      <Card
        className={`Accordion-Card ${disabled ? 'Accordion-Disabled' : ''}`}
        onClick={(e) => !disabled && handleSelectAccordion(e)}
        style={{ ...style }}
      >
        {children}
      </Card>
    </div>
  )
}

export default Accordion
