import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../../../api/accounts'
import AreYouSureDialog from '../../../components/AreYouSureDialog'
import UserAvatar       from '../../../components/UserAvatar'
import AvatarDialog     from '../Profile/UserAvatarDialog'
import UserFields       from './UserFields'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  saveDisabled: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    cursor: 'default'
  },
  saveEnabled: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: 'pointer'
  },
})

// TODO: Add ability to upload Avatar to media service

const UserDialog = ({
  classes,
  open,
  onClose,
  userDetail = {},
  thisUser = {},
}) => {
  const [ createUser ] = useCreateUserMutation()
  const [ updateUser ] = useUpdateUserMutation()
  const [ deleteUser ] = useDeleteUserMutation()

  const [ user,      setUser      ] = useState({})
  const [ isNewUser, setIsNewUser ] = useState(false)
  const [ isActive,  setIsActive  ] = useState(false)
  const [ isAdmin,   setIsAdmin   ] = useState(false)
  const [ updating,  setUpdating  ] = useState(false)
  const [ aysOpen,   setAysOpen   ] = useState(false)

  const [ avatarDialogOpen, setAvatarDialogOpen ] = useState(false)


  useEffect(() => {
    setUpdating(false)
  }, [open])

  useEffect(() => {
    if (userDetail) { // edit user
      setUser({ ...userDetail })
      setIsNewUser(false)
      setIsActive(userDetail.status === 'active')
      setIsAdmin(userDetail.app_roles?.account?.includes('admin'))
    }
    else { // add user
      setUser({
        username: '',
        name: '',
        email: '',
        contact: '',
        address: '',
        country: '',
        designation: '',
        status: 'active',
        app_roles: {
          account: ['user'],
        },
        password: '',
        temporaryPassword: true,
      })
      setIsNewUser(true)
      setIsActive(true)
      setIsAdmin(false)
    }
  }, [userDetail])

  // Only allow Company Admin to make changes to Team Members
  const canUpdate = thisUser.app_roles?.account?.includes('admin')

  function handleOnAvatarClick() {
    setAvatarDialogOpen(true)
  }

  function onAvatarDialogClose() {
    setAvatarDialogOpen(false)
  }

  function handleUpdate() {
    if (!canUpdate || updating)
      return

    setUpdating(true)

    let newAccountAppRoles = user.app_roles?.account || []
    if (isAdmin && !newAccountAppRoles.includes('admin'))
      newAccountAppRoles.push('admin')
    else if (!isAdmin && newAccountAppRoles.includes('admin'))
      newAccountAppRoles = newAccountAppRoles.filter(role => role !== 'admin')

    const newUser = {
      user_id: user.user_id,
      username: user.username,
      name: user.name,
      email: user.email,
      contact: user.contact,
      address: user.address,
      country: user.country,
      designation: user.designation,
      status: isActive ? 'active' : 'inactive',
      app_roles: {
        ...user.app_roles,
        account: newAccountAppRoles,
      }
    }

    if (isNewUser) {
      newUser.company_id = thisUser.company.company_id
      newUser.password   = user.password
      newUser.temporaryPassword = true
      console.log('create newUser', newUser)
      createUser(newUser)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status === 'success') {
          toast.success('User added')
          onClose()
        } else {
          console.log('Failed to add user', fulfilled)
          toast.warn(`Failed to add user: ${fulfilled.data?.users}`)
          setUpdating(false)
        }
      })
      .catch(rejected => {
        console.log('Error adding user', rejected)
        setUpdating(false)
        toast.error('Error adding user')
      })
    }
    else {
      console.log('update newUser', newUser)
      updateUser(newUser)
      .unwrap()
      .then(fulfilled => {
        console.log('update fulfilled', fulfilled)
        if (fulfilled.status === 'success') {
          toast.success('User updated')
          onClose()
        } else {
          setUpdating(false)
          toast.warn('Failed to update user')
        }
      })
      .catch(rejected => {
        console.log('update rejected', rejected)
        setUpdating(false)
        toast.error('Error updating user. Try again later')
      })
    }
  }

  function handleDelete(ok) {
    setAysOpen(false)
    if (!ok || !canUpdate || updating)
      return

    setUpdating(true)
    console.log('handleDelete userId', user)

    deleteUser(user.user_id)
    .unwrap()
    .then(fulfilled => {
      console.log('delete fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        toast.success('User deleted')
        onClose()
      } else {
        setUpdating(false)
        toast.warn('Failed to delete user')
      }
    })
    .catch(rejected => {
      console.log('delete rejected', rejected)
      setUpdating(false)
      toast.error('Error deleting user. Try again later')
    })
  }

  return (<>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'>
      <DialogTitle>
        { userDetail ? `About ${userDetail.name}` : `Add new user in ${thisUser.company.name}` }
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          {!isNewUser &&
            <Grid item md={2}>
              <UserAvatar user={userDetail} size={'preview'} onClick={handleOnAvatarClick} />
            </Grid>
          }
          <Grid item md={10}>
            <UserFields
              isNewUser={isNewUser}
              canUpdate={canUpdate}
              user={user}
              setUser={setUser}
            />
          </Grid>
          <Grid item md={2}>
          </Grid>
          <Grid item md={5} style={{ padding: '0 16px' }}>
            <Typography variant='subtitle2'>User is Active</Typography>
            <Switch
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              color='primary'
              disabled={!canUpdate || updating}
            />
            <br />
            { canUpdate &&
              <Typography variant='caption'>
                You can temporarily suspend users by deactivating their user account.
                To permenantly delete a user, click Delete.
              </Typography>
            }
          </Grid>
          <Grid item md={5} style={{ padding: '0 16px' }}>
            <Typography variant='subtitle2'>User has Company Admin Role</Typography>
            <Switch
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
              color='primary'
              disabled={!canUpdate || updating}
            />
            <br />
            { canUpdate &&
              <Typography variant='caption'>
                By making this user a Company Admin, he or she will have the same account
                permissions as you, such as making changes to other team members.
              </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ gap: '16px' }}>
        <Button onClick={onClose}>
          Close
        </Button>
        { canUpdate && !isNewUser &&
          <Button color='secondary'
            onClick={() => setAysOpen(true)}>
            Delete
          </Button>
        }
        { canUpdate &&
          <Button
            onClick={handleUpdate}
            className={!updating ? classes.saveEnabled : classes.saveDisabled}>
            { updating ? 'Saving...' : isNewUser ? 'Add' : 'Update' }
          </Button>
        }
      </DialogActions>
    </Dialog>
    {avatarDialogOpen &&
      <AvatarDialog user={user} isOpen={avatarDialogOpen} onClose={onAvatarDialogClose} />
    }
    { aysOpen &&
      <AreYouSureDialog
        open={aysOpen}
        onClose={() => setAysOpen(false)}
        message={<>
          <b>Are you sure you want to delete {userDetail.name}?</b><br /><br />
          <i>You can temporarily suspend a user by switching it to inactive. Note: deleted users
          cannot have their email address reused for audit reasons.</i>
        </>}
        onOK={handleDelete}
      />
    }
  </>)
}

export default withStyles(styles)(UserDialog)
