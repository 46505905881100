import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useMyCompanyId,
  useGetSubscriptionsQuery,
} from '../../../api/accounts'
import applications from '../../../utils/applications'
import AppDetails from './AppDetails'
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  logoContainer: {
    width: '260px',
    padding: theme.spacing(1, 2),
  },
  logo: {
    width: '100%',
  },
  text: {
    padding: theme.spacing(1, 2),
  }
})

const apps = applications.filter(app => app.status === 'active')

function Applications({ classes }) {
  const [ selectedAppIndex, setSelectedAppIndex ] = useState(-1)
  const company_id = useMyCompanyId()
  const { data: subData, error: subError, isLoading: subLoading } = useGetSubscriptionsQuery({
    company_id
  })

  if (!subLoading) {
    if (subError) {
      toast.error('Error loading subscriptions')
      console.log('Error loading subscriptions:', subError)
    } else if (subData.status !== 'success') {
      toast.warn('Failed loading subscriptions')
      console.log('Failed loading subscriptions:', subData)
    }
  }

  const subscriptions = subData?.data?.subscriptions || []
  const sortedSubs = [ ...subscriptions ].sort((a, b) => b.end_date - a.end_date)

  const now = new Date().getTime()

  function handleLearnMore(i) {
    if (apps[i].key === 'facilities') {
      window.location = '/apps/facilities'
    } else {
      setSelectedAppIndex(i)
    }
  }

  if (selectedAppIndex >= 0)
    return <AppDetails
      app={apps[selectedAppIndex]}
      sortedSubs={sortedSubs}
      onBack={() => setSelectedAppIndex(-1)}
    />
  else
    return <List>
      { apps.map((app, i) => <Fragment key={app.key}>
          <ListItem
            alignItems='center'>
            <ListItemAvatar className={classes.logoContainer}>
              <img alt={app.key} src={app.logo} className={classes.logo} />
            </ListItemAvatar>
            <ListItemText
              className={classes.text}
              primary={app.name}
              secondary={app.description} />
            <ListItemSecondaryAction>
              { sortedSubs.some(sub =>
                  sub.app === app.key && now > sub.start_date && now < sub.end_date) &&
                <Button variant='contained' color='primary'
                  onClick={() => window.open(app.link, '_blank')}>
                  Launch
                </Button>
              } <Button variant='outlined' color='primary'
                onClick={() => handleLearnMore(i)}>
                Learn More
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider component='li' />
      </Fragment>) }
    </List>
}

export default withStyles(styles)(Applications)
