
// Status: 'prelaunch' -> ('active' | 'passive') -> 'archived'
// TODO: Add official links to the marketing pages for pricing / subscription
// TODO: To make this database driven together with Subscription Service
// TODO: In database, stg to go to stg links, int to int links, etc.
const applications = [
  {
    key: 'tenera',
    name: 'Tenera UAS',
    logo: '/apps/tenera_logo.png',
    link: 'https://tenera.garuda.io',
    description: ['Drone Sprayer System for Palm Plantations'],
    status: 'active',
  },
  {
    key: 'overwatch',
    name: 'Overwatch UAS',
    logo: '/apps/overwatch_logo.png',
    link: 'https://overwatch.garuda.io',
    description: ['Drone Infrastructure Management System'],
    status: 'active'
  },
  {
    key: 'facilities',
    name: 'Facilities 4.0',
    logo: '/apps/facilities_logo.png',
    link: 'https://facilities.garuda.io',
    description: ['Facilities Management System for High Rise Facade Inspections'],
    status: 'active',
  },
  {
    key: 'my_drone_fleets',
    name: 'MyDroneFleets',
    logo: '/apps/mydronefleets_logo.png',
    link: 'https://track.mydronefleets.com',
    description: ['Network Remote ID Drone Tracking for Cities and Enterprises'],
    status: 'active',
  },
  {
    key: 'plex_horizon',
    name: 'Plex Horizon',
    logo: '/apps/horizon_logo.png',
    link: 'https://gcs.garuda.io',
    description: ['Drone Flight Control System for BVLOS Drone Operations'],
    status: 'active',
  },
  {
    key: 'account',
    name: 'Garuda Plex Account',
    logo: '/apps/plex_logo.png',
    link: 'https://accounts.garuda.io',
    description: ['Your Profile data, Security settings, and Apps configuration'],
    status: 'passive',
  },
  /// ARCHIVED - for backwards compatibility ///
  {
    key: 'plantation',
    name: 'Plantation 4.0',
    logo: '/apps/plantation_logo.png',
    link: 'https://plantation.garuda.io',
    description: ['Plantation Management System for Oil Palm and Rice Plantations'],
    status: 'archived',
  },
  {
    key: 'plex',
    name: 'Garuda Plex',
    logo: '/apps/plex_logo.png',
    link: 'https://plex.garuda.io',
    description: ['Drone Fleet Management Platform for Professional Drone Operations'],
    status: 'archived',
  },
  {
    key: 'uato',
    name: 'UATO.sg',
    logo: '/apps/uato_logo.png',
    link: 'https://fly.uato.sg',
    description: ['Singapore Unmanned Aircraft Training and Assessment System'],
    status: 'archived',
  },
  {
    key: 'coverflight',
    name: 'CoverFlight Professional Drone Insurancce',
    logo: '',
    link: '',
    description: ['Archived'],
    status: 'archived',
  },
]

export default applications
