import { useState } from 'react'
import { toast } from 'react-toastify'
import { startOfToday } from 'date-fns'
import { useMyUserId } from '../../../../api/accounts'
import {
  useGetClientsQuery,
  useDeleteClientMutation,
} from '../../../../api/identity'
import AreYouSureDialog from '../../../../components/AreYouSureDialog'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  EventBusy,
  PersonRemove,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const TableHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1)
}))

function TeamCredentials({ teamMembers, onView, onCreate }) {
  const user_id = useMyUserId()
  const [ toDelete, setToDelete ] = useState(null)
  const [ deleteClient ] = useDeleteClientMutation()
  const { data: clientsData } = useGetClientsQuery()
  const clients = clientsData?.data?.clients || []

  function handleDelete() {
    deleteClient(toDelete.client_id)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status === 'success') {
        toast.success(`${toDelete.client_name} deleted`)
        setToDelete(null)
      } else {
        console.log('Failed to delete client', fulfilled)
        toast.warn('Failed to delete client')
      }
    })
    .catch(rejected => {
      console.log('Error deleting client', rejected)
      toast.error('Error deleting client')
    })
  }

  function getTeamMember(userId) {
    return teamMembers.find(member => member.user_id === userId)
  }
  const isCompanyAdmin = getTeamMember(user_id)?.app_roles?.account?.includes('admin')

  return (
    <Box>
      <TableHeader>
        <Typography variant='h5'>
          Developer Credentials
        </Typography>
        <Button variant='contained' color='primary'
          onClick={onCreate}>
          Add Developer Credentials
        </Button>
      </TableHeader>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Team Member</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Issued</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { clients.map((client, index) => {
                const teamMember = getTeamMember(client.user_id)
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {client.client_name}
                      {(client.client_secret_expires_at > 0 &&
                        client.client_secret_expires_at < startOfToday().getTime()) &&
                        <Chip avatar={<EventBusy />} label='Expired' />
                      }
                    </TableCell>
                    <TableCell>
                      {teamMember?.name || '-'} ({teamMember?.email || '-'})
                    </TableCell>
                    <TableCell>
                      {client.client_id}
                    </TableCell>
                    <TableCell>
                      {new Date(client.client_id_issued_at * 1000).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      { client.user_id === user_id &&
                        <Button variant='outlined' color='primary'
                          onClick={() => { onView(client) }}>
                          View
                        </Button>
                      }
                      { isCompanyAdmin &&
                        <IconButton onClick={() => { setToDelete(client) }}>
                          <PersonRemove />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      { toDelete &&
        <AreYouSureDialog
          open={true}
          onClose={() => { setToDelete(null) }}
          title='Delete Client Credential'
          message={`Are you sure you want to delete ${toDelete.client_name}?`}
          action='Delete'
          onOK={(ok) => { ok ? handleDelete() : setToDelete(null) }}
        />
      }
    </Box>
  )
}

export default TeamCredentials
