import { makeStyles } from '@mui/styles'

const drawerWidth = 320

const useStyles = makeStyles((theme) => {
  return ({
    title: {
      flexGrow: 1,
    },
    LeftNav: {
      height: '100%',
    },
    root: {
      display: 'flex'
    },
    drawer: {
      width: '320px',
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      },
      backgroundColor: theme.palette.background.main,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width:      drawerWidth,
      background: theme.palette.common.white,
      color:      theme.palette.background.main,
      fill:       theme.palette.background.main,
    },
    content: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        // height: '100%' // Why is this better? TODO: figure out how to disable the scroll bar under overflow.
      }
    },
    subHeader: {
      textAlign: 'left',
      color: 'rgba(66, 66, 66, .5)',
      margin: '24px 16px 0 24px'
    },
    listItemText: {
      fontWeight: 600,
      paddingLeft: '8px'
    }
  })
})

export default useStyles
