// 
// This page is not protected because new company needs its first user
//
// THIS PAGE IS A WORK IN PROGRESS - MIGRATING OVER FROM EJS IN IDENTITY
//
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { countries } from 'countries-list'
import {
  useVerifyCompanyActivationTokenQuery,
  useActivateCompanyMutation,
} from '../../../api/accounts'
import {
  validUsername,
  validPassword,
} from '../../../utils'
import {
  ExternalLink
} from '../../../components'
import {
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import logo from '../../../assets/img/garuda.png'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const styles = theme => ({
  body: {
    width:  '100vw',
    height: '100vh',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.bluegrey.dark,
    overflow: 'hidden',
  },
  container: {
    backgroundColor: theme.palette.common.white,
    width:   theme.spacing(60),
    padding: theme.spacing(4),
    margin:  '0 auto',
    borderRadius: theme.spacing(1),
    boxShadow: theme.spacing(1, 1),
  },
  instruction: {
    padding: theme.spacing(2, 0),
  },
  action: {
    width:     '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
})

function Activate({ classes }) {
  const query = useQuery()
  const email = query.get('e')
  const token = query.get('t')
  const { data: tokenValidData } = useVerifyCompanyActivationTokenQuery({
    email, token
  }, {
    skip: !(email && token)
  })
  const tokenValid = tokenValidData?.status === 'success'

  const [ name,     setName     ] = useState('')
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ country,  setCountry  ] = useState('')

  const [ nameErr,     setNameErr     ] = useState('')
  const [ usernameErr, setUsernameErr ] = useState('')
  const [ passwordErr, setPasswordErr ] = useState('')
  // const [ countryErr,  setCountryErr  ] = useState('')

  const [ done,  setDone  ] = useState(false)

  const [ activateCompany, activateResult ] = useActivateCompanyMutation()

  function validName(n) {
    return n?.length > 0 || false
  }

  function editName(e) {
    const n = e.target.value
    setNameErr(validName(n) ? '' : 'Please key in the company administrator\'s full name')
    setName(n)
  }

  function editUsername(e) {
    const u = e.target.value
    setUsernameErr(validUsername(u) ? '' : 'Username can only contain alphabets, digits, dash, or underscore, and between 3 and 255 characters in length')
    setUsername(u)
  }

  function editPassword(e) {
    const p = e.target.value
    setPasswordErr(validPassword(p) ? '' : 'Password can only ')
    setPassword(p)
  }

  function selectCountry(e) {
    setCountry(e.target.value)
  }

  function handleSubmit() {
    console.info('Create a new admin', name, email, username, password, country)

    if (!(validName(name) && validUsername(username) && validPassword(password))) {
      toast.warn('Please fix the problems and try again')
      return
    }

    activateCompany({
      email,
      token,
      name,
      username,
      password,
      country,
    })
    .unwrap()
    .then(fulfilled => {
      console.log('activate fulfilled', fulfilled)
      console.log('activate result', activateResult)

      if (fulfilled.status === 'success') {
        setDone(true)
        toast.success('Company Activated')
      } else {
        toast.warn('Failed to activate company')
      }
    })
    .catch(rejected => {
      console.log('activate rejected', rejected)
      toast.error('Error activating company. Try again later')
    })
  }

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <img src={logo} width='320' alt='logo' />
        { !tokenValid ?
          <div className={classes.instruction}>
            <Typography variant='h6'>Link Expired</Typography>
            <Typography>
              This activation link has expired. Please sign up again, or
              contact our customer support if you believe this to be an error.
            </Typography>
          </div>
        : done ? <>
          <div className={classes.instruction}>
            <Typography variant='h6'>Activation successful</Typography>
            <Typography>
              Your Garuda Plex account has been created successfully.
              Please proceed to login to manage your account.
            </Typography>
          </div>
          <div className={classes.action}>
            <Button variant='contained' color='primary'
              onClick={() => { window.location.href = '/' }}>
              Login
            </Button>
          </div>
        </> : <>
          <div className={classes.instruction}>
            <Typography variant='h6'>Company Administrator</Typography>
            <Typography>
              To complete your Company activation, create a Company Administrator
              account for <b>{email}</b>
            </Typography>
            <TextField fullWidth
              label='Name'
              value={name}
              autoFocus={true}
              autoComplete='name'
              error={!!nameErr}
              helperText='Full name of the company admin'
              onChange={editName}
            />
            <TextField fullWidth
              label='Username'
              value={username}
              autoComplete='username'
              error={!!usernameErr}
              helperText='Login username (alphanumeric)'
              onChange={editUsername}
            />
            <TextField fullWidth
              label='Password'
              type='password'
              value={password}
              error={!!passwordErr}
              autoComplete='new-password'
              helperText='Login Password (min 8 char)'
              onChange={editPassword}
            />
            <TextField select fullWidth
              label='Country'
              value={country}
              onChange={selectCountry}>
              { Object.keys(countries).map(cn => (
                <MenuItem key={cn} value={countries[cn].name}>{countries[cn].name}</MenuItem>
              )) }
            </TextField>
            <Typography>
              By clicking Submit, you agree to Garuda Robotics <ExternalLink
              url='https://garuda.io/terms'>Terms of Use</ExternalLink> and <ExternalLink
              url='https://garuda.io/privacy'>Privacy Policy</ExternalLink>.
            </Typography>
            <Button color='primary' variant='contained'
              onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </> }
      </div>
    </div>
  )
}

export default withStyles(styles)(Activate)