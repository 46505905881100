import { useState } from 'react'
import {
  useMyCompanyId,
  useGetUsersQuery,
  useGetDevelopersQuery,
} from '../../../api/accounts'
import APICategoryList         from './components/APICategoryList'
import TeamCredentials         from './components/TeamCredentials'
import CredentailsDialog       from './components/CredentailsDialog'
import CreateCredentialsDialog from './components/CreateCredentialsDialog'
import {
  Box,
  Typography,
} from '@mui/material'

function Developer() {
  const company_id = useMyCompanyId()
  const { data, isLoading } = useGetDevelopersQuery({ company_id })
  const developer = data?.data?.developers[0]
  const { data: teamData } = useGetUsersQuery({ company_id })
  const teamMembers = teamData?.data?.users || []

  const [ client, setClient ] = useState({})
  const [ open,   setOpen   ] = useState(false)
  const [ create, setCreate ] = useState(false)

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      { !isLoading && !developer &&
        <Typography>
          Developer Programme is not enabled for your account.
        </Typography>
      }
      { developer && <>
        <Box>
          <Typography variant='h5'>API Categories</Typography>
          <APICategoryList developer={developer} />
        </Box>
        <TeamCredentials
          teamMembers={teamMembers}
          onCreate={() => { setCreate(true) }}
          onView={client => {
            setOpen(true)
            setClient(client)
          }}
        />
        { client && open &&
          <CredentailsDialog
            client={client}
            onClose={() => { setOpen(false) }}
          />
        }
        { create &&
          <CreateCredentialsDialog
            onClose={() => { setCreate(false) }}
            companyId={company_id}
            teamMembers={teamMembers}
          />
        }
      </>
    }
    </Box>
  )
}

export default Developer
