// 
// This page is not protected because users need to set their new passwords
//
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import {
  useVerifyPasswordResetTokenQuery,
  useNewPasswordMutation,
} from '../../../api/accounts'
import { validPassword } from '../../../utils'
import {
  Button,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import logo from '../../../assets/img/garuda.png'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const styles = theme => ({
  body: {
    width:  '100vw',
    height: '100vh',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.bluegrey.dark,
    overflow: 'hidden',
  },
  container: {
    backgroundColor: theme.palette.common.white,
    width:   theme.spacing(60),
    padding: theme.spacing(4),
    margin:  '0 auto',
    borderRadius: theme.spacing(1),
    boxShadow: theme.spacing(1, 1),
  },
  instruction: {
    padding: theme.spacing(2, 0),
  },
  action: {
    width:     '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
})

function NewPassword({ classes }) {
  const query = useQuery()
  const email = query.get('e')
  const token = query.get('t')
  const { data: tokenValidData } = useVerifyPasswordResetTokenQuery({ email, token }, {
    skip: !(email && token)
  })
  const tokenValid = tokenValidData?.status === 'success'
  const [ newPwd, setNewPwd ] = useState('')
  const [ error,  setError  ] = useState('')
  const [ completed, setCompleted ] = useState(false)
  const [ setNewPassword ] = useNewPasswordMutation()

  if (!tokenValidData) {
    return 'Loading ...'
  }

  if (tokenValidData && !tokenValid) {
    return (
      <div style={{ padding: '24px' }}>
        <img src={logo} width='320' alt='logo' />
        <Typography>Your password reset token is invalid or expired.</Typography>
      </div>
    )
  }

  function handleChangeNewPwd(evt) {
    const val = evt.target.value
    setNewPwd(val)

    if (val.length === 0) 
      setError('New password cannot be empty')
    else if (!validPassword(val))
      setError('New password must have at least 8 characters, with at least one alphabet and one digit')
    else
      setError('')
  }

  function handleSubmit() {
    if (error || !validPassword(newPwd))
      return

    setNewPassword({ email, token, new_password: newPwd })
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status === 'success') {
        toast.success('Password changed')
        setCompleted(true)
      } else {
        console.log('Failed to change password', fulfilled)
        setError(fulfilled.data?.users || fulfilled.data)
      }
    })
    .catch(rejected => {
      toast.error('Error changing password')
      console.log('Error changing password', rejected)
      setError(rejected)
    })
  }

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <img src={logo} width='320' alt='logo' />
        { completed ? <>
          <div className={classes.instruction}>
            <Typography>Password for <b>{email}</b> has been changed successfully.</Typography>
          </div>
          <div className={classes.action}>
            <Button variant='contained' color='primary'
              onClick={() => { window.location.href = '/' }}>
              Manage my Garuda Plex Account
            </Button>
          </div>
        </> : <>
          <div className={classes.instruction}>
            <Typography>Set a new password for <b>{email}</b></Typography>
          </div>
          <TextField
            label='New Password'
            variant='outlined'
            type='password'
            fullWidth
            value={newPwd}
            onChange={handleChangeNewPwd}
            error={!!error}
            helperText={error.length > 0 ? error :
              validPassword(newPwd) ? 'Strong password' :
              'Must have at least 8 characters, with at least 1 alphabet and 1 digit'}
          />
          <div className={classes.action}>
            <Button variant='contained' color='primary'
              onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </> }
      </div>
    </div>
  )
}

export default withStyles(styles)(NewPassword)
