export function standardHeaders(headers, { getState }) {
  const user = getState().oidc?.user
  if (user)
    headers.set('Authorization', `Bearer ${user.access_token}`)
  return headers
}

// For now, works for strings, arrays, booleans (beware of type)
export function makeQueryString(queryParams = {}, searchFields, customCallback) {
  const paramsArray = []
  searchFields.forEach(field => {
    if (queryParams[field]) {
      const encodedData = encodeURIComponent(queryParams[field])
      if (encodedData) // so that backend does not see any empty strings
        paramsArray.push(`${field}=${encodedData}`)
    }
  })
  if (customCallback)
    paramsArray.push(customCallback())
  return paramsArray.length > 0 ? ('/?' + paramsArray.join('&')) : '/'
}
