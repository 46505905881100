import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useDecodedAccessToken,
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
} from '../../../api/accounts'
import { countries } from 'countries-list'
import { validEmail, validContact, getListOfTimezones } from '../../../utils'
import { CompanyAvatar } from '../../../components'
import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyAvatarDialog from './CompanyAvatarDialog'

const styles = theme => ({
  avatar: {
    width:      theme.spacing(20),
    height:     theme.spacing(20),
    background: theme.palette.bluegrey.main,
    // cursor:     'pointer', // only when we can upload new picture
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gridContainer: {
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  field: {
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1)
  },
})

function Company({ classes }) {
  const token = useDecodedAccessToken()
  const company_id = token.company_id
  const {
    data:      companyData,
    error:     companyError,
    isLoading: companyLoading
  } = useGetCompanyByIdQuery({company_id})

  if (!companyLoading) {
    if (companyError) {
      console.log('Error loading company:', companyError)
    } else if (companyData.status !== 'success') {
      console.log('Failed loading company:', companyData)
    }
  }

  const [ updateCompany, updateResult ] = useUpdateCompanyMutation()

  const [ company,  setCompany  ] = useState({})
  const [ editable, setEditable ] = useState(false)
  const [ avOpen,   setAvOpen   ] = useState(false)
  
  useEffect(() => {
    setCompany(prevCompany => {
      return companyData?.data?.company|| {}
    })
  }, [companyData])
  const timezones =  getListOfTimezones()
  const handleSubmitUpdateCompany = () => {
    const newCompany = {
      company_id: company.company_id,
      name:       company.name,
      email:      company.email,
      contact:    company.contact,
      address:    company.address,
      country:    company.country,
      properties: {
        pref_default_timezone: company.properties.pref_default_timezone
      }
    }

    updateCompany(newCompany)
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        console.log('update result', updateResult)
        toast.success('Company updated')
        setEditable(false)
      } else {
        toast.warn('Failed to update company')
      }
    })
    .catch(rejected => {
      console.log('udpate rejected', rejected)
      toast.error('Error updating company. Try again later')
    })
  }
  const listOfTimezones = getListOfTimezones()
  function checkTimezone(timeZone){
    if (typeof timeZone !== 'string' || timeZone.length < 3 || timeZone.length > 32) return false
  
    if (!listOfTimezones.includes(timeZone)) return false
    return timeZone
  }
  function checkEmail(email) {
    if (email === '' || validEmail(email))
      return [ true, '' ]
    else
      return [ false, 'Invalid email format' ]
  }

  function checkContact(contact) {
    // TODO: Harmonise against dial code logic in customer-central and accounts-service
    // Present logic harmonises against global-utils (our own spefication)
    if (contact === '' || validContact(contact))
      return [ true, '' ]
    else
      return [ false, 'Contact must be only digits, dash, dot, plus, and brackets, between 7 and 20 characters' ]
  }

  const fields = [
    ['name', 'Company Name'],
    ['email', 'Email (Main)', checkEmail],
    ['contact', 'Contact (Main)', checkContact],
    ['address', 'Address'],
    ['country', 'Country'],
    ['timezone', 'Timezone', checkTimezone]
  ]

  function readOnlyFields() {
    return (<>{
      fields.map(([key = '', label = '']) => (
        <div className={classes.field} key={key}>
          <Typography variant='overline' style={{ lineHeight: '1em' }}>
            {label}
          </Typography>
          <Typography variant='h5'>
            {(key !== 'timezone' ? company[key] : company?.properties?.pref_default_timezone )|| '-'}
          </Typography>
        </div>
      ))
    }</>)
  }

  function editableFields() {

    const onChange = (e, key) => {
      const newCompany = JSON.parse(JSON.stringify(company))
      if(key ==='timezone')
        newCompany.properties.pref_default_timezone = e.target.value
      else
        newCompany[key] = e.target.value
      setCompany(newCompany)
    }
    console.info(fields)
    return (
      <>
        { fields.map(([key = '', label = '', isValid = () => [ true, '' ], disabled = false]) => {
          console.info(key)
          if (disabled)
            return <div className={classes.field} key={key}>
              <Typography variant='overline' style={{ lineHeight: '1em' }}>
                {label}
              </Typography>
              <Typography variant='h5'>
                {(key !== 'timezone' ? company[key] : company.properties.pref_default_timezone )|| '-'}
              </Typography>
            </div>
          else if (key === 'country')
            return <TextField select fullWidth
              className={classes.textField}
              key={key}
              label={label}
              variant='outlined'
              value={company[key] || ''}
              onChange={e => onChange(e, key)}>
              { Object.keys(countries).map(cn => (
                <MenuItem key={cn} value={countries[cn].name}>{countries[cn].name}</MenuItem>
              )) }
            </TextField>
          else if(key === 'timezone')
            return <TextField select fullWidth
              className={classes.textField}
              key={key}
              label={label}
              variant='outlined'
              value={company?.properties?.pref_default_timezone || ''}
              onChange={e => onChange(e, key)}>
              { Object.keys(timezones).map(tz => (
                <MenuItem key={tz} value={timezones[tz].name}>{timezones[tz].name} (GMT {timezones[tz].zString})</MenuItem>
              )) }
            </TextField>
          else {
            const [ valid, errorMsg ] = isValid(company[key])
            return <TextField fullWidth
              className={classes.textField}
              key={key}
              label={label}
              variant='outlined'
              type='text'
              value={company[key] || ''}
              onChange={e => onChange(e, key)}
              error={!valid}
              helperText={errorMsg}
            />
          }
        }) }
      </>
    )
  }

  const editButton = <Button
    onClick={() => setEditable(true)}
    variant='contained'
    color='primary'>
    Edit Company
  </Button>

  const cancelButton = <Button
    onClick={() => setEditable(false)}
    variant='outlined'
    color='primary'>
    Cancel
  </Button>

  const saveButton = <Button
    onClick={handleSubmitUpdateCompany}
    variant='contained'
    color='primary'>
    Save
  </Button>

  return (
    <Container>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item md={9}>
          <Grid item className={classes.field}>
            <CompanyAvatar
              company={company}
              size={'preview'}
              onClick={() => { setAvOpen(true) }}
            />
          </Grid>
          <Grid item className={classes.field}>
            <Button
              onClick={() => { setAvOpen(true) }}
              variant='contained'
              color='primary'>
              Upload Avatar
            </Button>
          </Grid>
          { editable ?
            <>
              { editableFields() }
              { cancelButton } { saveButton }
            </>
          : <>
              { readOnlyFields() }
              <div className={classes.field}>
                { token.app_roles?.account?.includes('admin') && editButton }
              </div>
            </>
          }
        </Grid>
      </Grid>
      <CompanyAvatarDialog
        open={avOpen}
        onClose={() => setAvOpen(false)}
        company={company}
      />
    </Container>
  )
}

export default withStyles(styles)(Company)
