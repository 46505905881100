import { toast } from 'react-toastify'
import {
  useMyCompanyId,
  useMyUserId,
  useGetUsersQuery,
  useUpdateUserMutation,
} from '../../../api/accounts'
import {
  // SearchBar,
  WaitDialog,
} from '../../../components'
import UsersTable from './UsersTable'
import {
  Container,
} from '@mui/material'

function Roles() {
  const company_id = useMyCompanyId()
  const user_id    = useMyUserId()

  const { data, error, isLoading } = useGetUsersQuery({ company_id })
  const [ updateUser ] = useUpdateUserMutation()

  if (!isLoading) {
    if (error) {
      toast.error('Error loading team')
      console.log('Error loading team:', error)
    } else if (data.status !== 'success') {
      toast.warn('Failed loading team')
      console.log('Failed loading team:', data)
    }
  }
  const users = data?.data?.users || []

  // const handleSearch = ({ target }) => {
    // const { value } = target
    // const searchedRows = allRows.filter(row => {
    //   return `${row.name}`.toLowerCase().indexOf(`${value}`.toLowerCase()) > -1 ||
    //     `${row.email}`.toLowerCase().indexOf(`${value}`.toLowerCase()) > -1 ||
    //     `${row.roleStatus}`.toLowerCase().indexOf(`${value}`.toLowerCase()) > -1
    // })
    // setPage(0)
    // setRows(searchedRows)
  // }

  function handleUpdate(newUser) {
    updateUser(newUser)
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        toast.success('Roles updated')
      } else {
        let errorMessage = ''
        if (fulfilled.user)
          errorMessage += fulfilled.user
        if (fulfilled.company)
          errorMessage += fulfilled.company
        toast.warning('Failed to update roles: ' + errorMessage)
      }
    })
    .catch(rejected => {
      console.log('udpate rejected', rejected)
      toast.error('Error updating roles. Try again later.')
    })
  }

  if (isLoading) {
    return <WaitDialog msg='Loading Application Roles...' />
  }
  const canUpdate = users.find(u => u.user_id === user_id)?.app_roles?.account?.includes('admin')
  return (
    <Container>
      {/* <SearchBar onChange={handleSearch} /> */}
      <UsersTable
        users={users}
        canUpdate={canUpdate}
        onUpdate={handleUpdate}
      />
    </Container>
  )
}

export default Roles
