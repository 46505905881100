import { useEffect, useState } from 'react'
import {
  useMyUserId,
  useMyCompanyId,
  useGetUsersQuery,
} from '../../../api/accounts'
import UserAvatar from '../../../components/UserAvatar'
import UserDialog from './UserDialog'
import {
  Button,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  avatar: {
    width:      theme.spacing(4),
    height:     theme.spacing(4),
    background: theme.palette.bluegrey.main
  },
  img: {
    width: '100%',
    height: '100%',
  },
})

function Team({ classes }) {
  const company_id = useMyCompanyId()
  const user_id    = useMyUserId()
  const {
    data:      teamData,
    error:     teamError,
    isLoading: teamLoading,
  } = useGetUsersQuery({ company_id })

  if (!teamLoading) {
    if (teamError) {
      console.log('Error loading team:', teamError)
    } else if (teamData.status !== 'success') {
      console.log('Failed loading team:', teamData)
    }
  }

  const [ users, setUsers ] = useState([])
  const [ index, setIndex ] = useState(-2) // -2 Close, -1 New User, >= 0 Edit User

  useEffect(() => {
    if (!teamData)
      return
    const teamMembers = teamData.data?.users || []
    const users = teamMembers.map(user => {
      const accountRoles = user.app_roles?.account || []
      return { ...user,
        roleStatus: accountRoles.map(r => ({
          super_admin: 'Garuda Plex Super Admin',
          admin: 'Company Admin',
          user: 'User',
        }[r] || 'Unknown Account Role ' + r)).join(', ') || 'No Account Role'
      }
    })
    setUsers(users)
  }, [teamData])

  const thisUser = users.find(u => u.user_id === user_id)

  return (
    <Container>
      <div className={classes.header}>
        <Typography variant='h5'>
          { thisUser?.company?.name }
        </Typography>
        <Button variant='contained' color='primary'
          onClick={() => setIndex(-1)}>
          New User
        </Button>
      </div>
      <TableContainer component={Paper} className='TableContainer'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Account Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, i) => (
              <TableRow key={user.user_id} onClick={() => setIndex(i)} style={{ cursor: 'pointer' }}>
                <TableCell>
                  <UserAvatar user={user} size={'thumb'}/>
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.designation || user.role}</TableCell>
                <TableCell>{user.roleStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      { index > -2 &&
        <UserDialog
          open={index > -2}
          onClose={() => setIndex(-2)}
          userDetail={index === -1 ? null : users[index]}
          thisUser={thisUser}
        />
      }
    </Container>
  )
}

export default withStyles(styles)(Team)
