import { Route, Switch }   from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { history }         from './utils/store'
import { Callback, ProtectedRoute } from './oidc'
import GeneralProfile   from './pages/General/Profile'
import GeneralCompany   from './pages/General/Company'
import GeneralActivate  from './pages/General/Company/Activate'
import GeneralTeam      from './pages/General/Team'
import GeneralDeveloper from './pages/General/Developer'
import SecurityPassword from './pages/Security/Password'
import SecurityNewPwd   from './pages/Security/Password/NewPassword'
// import SecurityMFA      from './pages/Security/MFA'
import SecuritySessions from './pages/Security/Sessions'
import SecurityAuditLog from './pages/Security/AuditLog'
import AppsApplications from './pages/Apps/Applications'
import AppsRoles        from './pages/Apps/Roles'
import AppsUsage        from './pages/Apps/Usage'
import AppsPayment      from './pages/Apps/Payment'
import AppsHistory      from './pages/Apps/History'
// For this round, to be hardcoded first.
// To design DB driven system when we have 3 apps
import AppsFacilities   from './pages/Apps/Applications/facilities'
import Toast            from './components/Toast'
import theme            from './utils/site-theme'
import { CssBaseline }  from '@mui/material'
import {
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
export const protectedRoutes = [
  { path: '/', component: GeneralProfile },
  // General
  { path: '/general/profile',   component: GeneralProfile   },
  { path: '/general/company',   component: GeneralCompany   },
  { path: '/general/team',      component: GeneralTeam      },
  { path: '/general/developer', component: GeneralDeveloper },
  // Security
  { path: '/security/password', component: SecurityPassword },
  { path: '/security/sessions', component: SecuritySessions },
  { path: '/security/audit',    component: SecurityAuditLog },
  // Apps
  { path: '/apps/all',     component: AppsApplications },
  { path: '/apps/roles',   component: AppsRoles },
  { path: '/apps/usage',   component: AppsUsage },
  { path: '/apps/payment', component: AppsPayment },
  { path: '/apps/history', component: AppsHistory },
  // Specific Apps (not database driven yet)
  { path: '/apps/facilities', component: AppsFacilities },
]
export const openRoutes = [
  { path: '/callback',              component: Callback       },
  { path: '/security/password/new', component: SecurityNewPwd },
  { path: '/general/company/activate', component: GeneralActivate },
]
const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toast />
        <ConnectedRouter history={history}>
          <Switch>
            {openRoutes.map(route => (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
            {protectedRoutes.map(route => (
              <ProtectedRoute
                exact
                key={route.path}
                path={route.path}
                ProtectedComponent={route.component}
              />
            ))}
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default App