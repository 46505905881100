import { useState } from 'react'
import {
  Dialog,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  Fullscreen,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  scrollButton: {
    backgroundColor: 'transparent',
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  imageList: {
    flexWrap: 'nowrap',    
    transform: 'translateZ(0)', // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  },
  title: {
    color: theme.palette.common.white,
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
})

function AppScreenImageList({ classes, screens }) {
  const [ screenIndex, setScreenIndex ] = useState(-1)

  function scrollLeft() {
    document.getElementById('screens').scrollLeft -= 150
  }
  function scrollRight() {
    document.getElementById('screens').scrollLeft += 150
  }

  return (<>
    <div className={classes.root}>
      <IconButton onClick={scrollLeft} className={classes.scrollButton} size='large'>
        <ChevronLeft />
      </IconButton>
      <ImageList className={classes.imageList} cols={2.5} id='screens'>
        {screens.map((item, i) => (
          <ImageListItem key={i}>
            <img src={item.img} alt={item.title} />
            <ImageListItemBar
              title={item.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                <IconButton onClick={() => setScreenIndex(i)} size='large'>
                  <Fullscreen className={classes.title} />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <IconButton onClick={scrollRight} className={classes.scrollButton} size='large'>
        <ChevronRight />
      </IconButton>
    </div>
    { screenIndex >= 0 &&
      <Dialog
        fullWidth
        maxWidth='lg'
        open={screenIndex >= 0}
        onClose={() => setScreenIndex(-1)}>
        <img src={screens[screenIndex].img} alt={screens[screenIndex].title} />
      </Dialog>
    }
  </>)
}

export default withStyles(styles)(AppScreenImageList)
