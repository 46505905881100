import { useGetApiCategoriesQuery } from '../../../../api/accounts'
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const LineBreak = styled('span')({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
})

const openLink = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
}

function APICategoryList({ developer }) {
  const { data: catData } = useGetApiCategoriesQuery()
  const api_categories = catData?.data?.api_categories || []
  console.log('api_categories: ', api_categories)

  return (
    <List>
      { api_categories.map(cat => (
        <Box key={cat.key}>
          <ListItem alignItems='center'>
            <IOSSwitch
              sx={{ m: 1 }}
              disabled={developer[cat.key]?.status === 'suspended' }
              defaultChecked={developer[cat.key]?.status === 'active'}
            />
            <ListItemText
              sx={{ maxWidth: '50%' }} //temp fix for buttons overlapping
              primary={cat.name}
              secondary={<LineBreak>{cat.description}</LineBreak>}/>
            <ListItemSecondaryAction sx={{ maxWidth: '30%' }}>
              <Grid container
                spacing={1}
                alignItems='center'
                justifyContent='flex-end'>
                { cat.docs.map((doc, index) => (
                  <Grid item key={index}>
                    <Button
                      variant='outlined'
                      color='primary'
                      disabled={!(developer[cat.key]?.status === 'active')}
                      onClick={() => openLink(doc.link)}>
                      {doc.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider component='li' />
        </Box>
      ))}
    </List>
  )
}

export default APICategoryList


// const services = [
//   {
//     id: '1',
//     name: 'IAM & Accounts',
//     description: 'Manage company details, user profiles and application roles.',
//     docs: [
//       {name: 'Accounts', link: 'https://api.mydronefleets.com/accounts/developer-docs/'}
//     ],
//     key: 'core'
//   },
//   {
//     id: '2',
//     name: 'UAS Fleet Management',
//     description: 'Track and configure equipment lifecycle for unmanned aircraft systems, such as drones, payloads, docks, trackers, and controllers.',
//     docs: [
//       {name: 'Aircraft', link:'https://api.mydronefleets.com/aircraft/developer-docs/'},
//       {name: 'Config', link: 'https://api.mydronefleets.com/cfg/developer-docs/'},
//     ],
//     key: 'fleet'
//   },
//   {
//     id: '3',
//     name: 'Airspace & Environment',
//     description: 'Receive information about the world in which the UA operates in, such as airspace, no fly zone, weather, obstacles, landmarks, and boundaries.',
//     docs: [
//       {name: 'Airspace', link: 'https://api.mydronefleets.com/airspace/developer-docs/'},
//       {name: 'Geo', link: 'https://api.mydronefleets.com/geospatial/developer-docs/'},
//       {name: 'Weather', link: 'https://api.mydronefleets.com/meteorology/developer-docs/'}
//     ],
//     key:'world'
//   },
//   {
//     id: '4',
//     name: 'BVLOS Command & Control',
//     description: 'Remotely control the UA over the Internet, including planning flight paths and sessions, reading status and telemetry, and issuing commands.',
//     docs: [
//       {name: 'Live Flights', link: 'https://api.mydronefleets.com/live/developer-docs/'},
//       {name: 'Live Flights (WS)', link: 'https://studio.asyncapi.com/?url=https://api.mydronefleets.com/liveflights/async-api-preview-developer/'},
//       {name: 'Flight Plan', link: 'https://api.mydronefleets.com/flight/developer-docs/'}
//     ],
//     key:'bvlos'
//   },
//   {
//     id: '5',
//     name: 'SkyStream',
//     description: 'Stream, transcode, and distribute video streams from UA or any video capture devices.',
//     docs: [{name: 'Skystream', link: 'https://api.mydronefleets.com/skystream/developer-docs/'}],
//     key:'video'
//   },
//   {
//     id: '6',
//     name: 'Drone Simulator',
//     description: 'Simulate Cerana UAS (Hosted Cerana On-board Unit and ArduPilot SITL Sim).',
//     docs: [{name: 'Simulator', link: 'https://simulator.mydronefleets.com/'}],
//     key:'sim'
//   },
//   {
//     id: '7',
//     name: 'Security Surveillance',
//     description: 'Patrol campus perimeters, respond to incidents (e.g. AI detected fire / trespassing), search and rescue over large areas.',
//     docs: [{name: 'Security', link:'https://api.mydronefleets.com/security/developer-docs/'}],
//     key:'sec'
//   },
//   {
//     id: '8',
//     name: 'Building Facade Inspection',
//     description: 'Inspect building facades for AI detected defects and anomalies and follow up with rectifications.',
//     docs: [{name: 'Inspection', link: 'https://api.mydronefleets.com/inspection-ops/developer-docs/'}],
//     key: 'bfi'
//   },
//   {
//     id: '9',
//     name: 'Palm Plantation Spraying',
//     description: 'Map and spot spray insecticides on AI detected trees in oil palm estates.',
//     docs: [{name: 'plantation', link:'https://api.mydronefleets.com/plantation/developer-docs/'}],
//     key: 'palm'
//   },
//   {
//     id: '10',
//     name: 'Media Asset',
//     description: 'Store and retrieve UA related images, videos, documents, while masking away AI detected personally identifiable info (eg. face, car plate).',
//     docs: [{name: 'Media', link: 'https://media.mydronefleets.com/developer-docs/'}],
//     key: 'media'
//   },
// ]