import TopXCloseButton from '../../../../components/TopXCloseButton'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

const CredentialsDialog = ({ onClose, client }) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        Credentials | { client?.client_name }
      </DialogTitle>
      <DialogContent>
        <Box p={2} sx={{ display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            disabled
            label='Client ID'
            defaultValue={client.client_id}
          />
          <TextField
            fullWidth
            gutterBottom
            disabled
            label='Client Secret'
            multiline
            rows={3}
            defaultValue={client.client_secret}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CredentialsDialog
