
import {
  Chip,
  Typography,
} from '@mui/material'
import {
  Add,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  roleCol: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
})

function AppRoleAndDesc({ classes, user, role, aar, appKey, canUpdate, onClick }) {

  // This role can only be managed in Customer Central.
  // No normal user should ever see it or know it exists.
  if (aar && aar.app === 'account' && aar.role === 'super_admin')
    return null

  return (
    <div className={classes.roleCol}>
      { user?.app_roles?.[appKey]?.includes(role) ?
        <Chip
          label={aar?.name || role}
          color='primary'
          clickable
          // onDelete={() => remove(app, role)}
          onDelete={canUpdate ? onClick : () => {}}
        />
      : <Chip
          size='small'
          label={aar?.name || role}
          clickable
          // onDelete={() => add(app, role)}
          onDelete={canUpdate ? onClick : () => {}}
          deleteIcon={<Add />}
        />
      }
      <Typography variant='body2'>{ aar?.description || '-' }</Typography>
    </div>
  )
}

export default withStyles(styles)(AppRoleAndDesc)
