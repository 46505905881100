import { ToastContainer, Slide } from 'react-toastify'
import theme from '../utils/site-theme'

import 'react-toastify/dist/ReactToastify.css'

function Toast() {
  return (
    <ToastContainer
      position='top-right'
      toastStyle={{
        backgroundColor: theme.palette.background.paper,
        fontWeight: 'bold'
      }}
      hideProgressBar={true}
      transition={Slide}
      autoClose={6500}
      newestOnTop={true}
      closeOnClick={true}
    />
  )
}

export default Toast