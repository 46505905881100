import {
  Box,
  Typography,
} from '@mui/material'

function Payment() {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography>Payment Methods is not enabled for your account.</Typography>
      <br />
      <Typography>Our sales representatives will connect with you directly regarding subscription licence payment.</Typography>
    </Box>
  )
}

export default Payment
