import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as oidcReducer } from 'redux-oidc'
import { createBrowserHistory } from 'history'

import { configureStore } from '@reduxjs/toolkit'
import { accountsApi }    from '../api/accounts'
import { identityApi } from '../api/identity'

export const history = createBrowserHistory()

// Redux DevTools Extension comes free with Redux Toolkit, install from Chrome Store to use
const store = configureStore({
  reducer: {
    router: connectRouter(history),
    oidc: oidcReducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
  },
  // default middleware already included thunk, immutableStateInvariant, serializableStateInvariant
  // https://redux-toolkit.js.org/api/getDefaultMiddleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // oidc.user is a class, issue still open, muting
      // https://github.com/maxmantz/redux-oidc/issues/169
      ignoredActions: ['redux-oidc/USER_FOUND'],
      ignoredPaths: ['oidc.user']
    },
  })
  .concat(routerMiddleware(history))
  .concat(accountsApi.middleware)
  .concat(identityApi.middleware)
})

export default store
