import { useState } from 'react'
import { toast } from 'react-toastify'
import { useUploadAvatarMutation } from '../../../api/accounts'
import { TopXCloseButton, UserAvatar } from '../../../components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material'
import {
  Portrait,
} from '@mui/icons-material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  uploadDisabled: {
    color: theme.palette.common.white,
  },
  uploadEnabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
})

const UserAvatarDialog = ({classes, user, open, onClose}) => {
  const [ uploadEnabled, setUploadEnabled ] = useState(false)
  const [ image,         setImage         ] = useState(null)
  const [ previewUrl,    setPreviewUrl    ] = useState(null)
  const [ uploadAvatar,  { isLoading }    ] = useUploadAvatarMutation()

  function handleOnClose() {
    onClose()
    setPreviewUrl(null)
    setUploadEnabled(false)
  }

  function handleUpload() {
    uploadAvatar({ user_id: user.user_id, file: image })
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status === 'success') {
          toast.success('Profile picture updated')
          onClose()
        } else {
          toast.warn('Failed to update profile picture')
          console.log('Failed to update profile picture', fulfilled)
          setUploadEnabled(true)
        }
      })
      .catch(rejected => {
        toast.error('Error updating profile picture')
        console.log('Error updating profile picture', rejected)
        setUploadEnabled(true)
      })
  }

  function handleFileInput(e) {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onerror = (e) => {
      console.log('Error reading file', e)
      toast.error(`Error reading file: ${reader.error.message}`)
      // TODO toast not working.
    }
    reader.onloadend = () => {
      if (reader.result) {
        setImage(file)
        setPreviewUrl(reader.result)
        setUploadEnabled(true)
      } else {
        console.log('Error reading file', file, reader)
        toast.error(`Error reading file`)
      }
    }
    reader.readAsDataURL(file)
  }

  return(
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth
      maxWidth='sm'>
      <TopXCloseButton onClick={handleOnClose} />
      <DialogTitle>
        Profile Picture
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          spacing={1}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <UserAvatar preview={previewUrl} user={user} size='fullscreen' />
          </Grid>
          <Grid item>
            <Button
              component='label'
              variant='contained'
              startIcon={<Portrait />}>
              Change Profile Picture
              <input hidden type='file' accept='image/png, image/gif, image/jpeg'
                onChange={handleFileInput}
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!uploadEnabled || isLoading}
          className={uploadEnabled ? classes.uploadEnabled : classes.uploadDisabled}
          onClick={handleUpload}>
          { isLoading ? 'Uploading...' : 'Upload' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(UserAvatarDialog)
