import { startOfDay, endOfDay } from '../../../../utils'
import {
  useMyCompanyId,
  useGetSubscriptionsQuery,
} from '../../../../api/accounts'
import applications from '../../../../utils/applications'
import ExternalLink from '../../../../components/ExternalLink'

import AppSubscriptionsTable from '../AppSubscriptionsTable'
import AppScreenImageList    from '../AppScreenImageList'
import {
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import banner  from './f4_banner.png'
import screen1 from './f4_1.png'
import screen2 from './f4_2.png'
import screen3 from './f4_3.png'
import screen4 from './f4_4.png'

const app = applications.filter(app => app.key === 'facilities')[0]

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  center: {
    width: '100%',
    textAlign: 'center'
  },

})

const screens = [
  {
    img: screen1,
    title: 'Manage Facilities',
  },
  {
    img: screen2,
    title: 'Manage Inspections',
  },
  {
    img: screen3,
    title: 'Inspection Gallery',
  },
  {
    img: screen4,
    title: 'FaultFinder AI',
  },
]

function AppFacilities({ classes }) {
  const company_id = useMyCompanyId()
  const {
    data:      subData,
    error:     subError,
    isLoading: subLoading,
  } = useGetSubscriptionsQuery({ company_id, app_key: 'facilities' }, {
    skip: !company_id
  })

  if (!subLoading) {
    if (subError) {
      console.log('Error loading subscriptions:', subError)
    } else if (subData.status !== 'success') {
      console.log('Failed loading subscriptions:', subData)
    }
  }

  const subscriptions = subData?.data?.subscriptions || []
  const sortedSubs = [ ...subscriptions ].sort((a, b) => b.end_date - a.end_date)

  const now = new Date().getTime()
  const subscribed = sortedSubs.some(sub =>
    sub.app === app.key &&
    now > startOfDay(sub.start_date).getTime() &&
    now < endOfDay(sub.end_date).getTime())

  function handleBack() {
    window.location = '/apps/all'
  }

  function handleSubscribeEnterprise() {
    const subject = '[acc] Subscribe / Renew Facilities 4.0'
    const body = `Hello,

Please subscribe me to Facilities 4.0 Enterprise.

My Name:

Company Name:

Contact Number (optional):

Subscription Start Date: ${new Date().toDateString()}

Subscription End Date: N/a (auto renew)

Billing Cycle: Pick One: Monthly ($828 / month) or Annually ($9,888 / year)

Please get back to me with an invoice for each billing cycle. I understand that
my subscription will only start when I complete the payment. I also agree to the
terms on https://garuda.io/terms

Thank you.
`
    window.open(`mailto:sales@garuda.io?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)  }

  function handleEnquiry() {
    const subject = '[acc] Enquiry on Facilities 4.0'
    const body = `Hello,

I would like to learn more about Facilities 4.0.

My Name:

Company Name:

I am a: Pick one or more: Facilities Management Company / Building Owner / Drone Service Provider / Facade Inspector / Competent Person

Please get back to me on email to arrange a time to speak.

Thank you.
`
    window.open(`mailto:sales@garuda.io?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)
  }
console.log('app', app, 'now', now, 'sortedSubs', sortedSubs)
sortedSubs.forEach(sub => {
  console.log('start', startOfDay(sub.start_date), 'end', endOfDay(sub.end_date))
})
  return (
    <Grid container justifyContent='center' spacing={3} className={classes.root}>
      <Grid item xs={3}>
        <img alt={app.key} src={app.logo} className={classes.fullWidth} />
      </Grid>
      <Grid item xs={9}>
        <Typography variant='h5'>{app.description}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <img src={banner} alt='Facilities 4.0 Summary' style={{ width: '100%', maxWidth: '960px'}} />
      </Grid>
      <Grid item xs={12} className={classes.fullWidth}>
        <AppScreenImageList screens={screens} />
      </Grid>
      { sortedSubs?.length > 0 && <>
        <Grid item xs={2}>
          <Typography variant='h6'>
            Your Subscriptions
          </Typography>
        </Grid>
        <Grid item xs={10} style={{ backgroundColor: '#eeeeee'}}>
          <AppSubscriptionsTable
            now={now} app={app}
            sortedSubs={sortedSubs}
          />
        </Grid>
      </>}
      <Grid item xs={2}>
        <Typography variant='h6'>Subscription Price</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='overline'>ENTERPRISE TIER</Typography>
        <Typography variant='h4'>SGD 9,888 per year</Typography>
        <Typography variant='h6'>or SGD 828 per month</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='overline'>SME TIER</Typography>
        <Typography variant='h4'>SGD 988 per year</Typography>
        <Typography variant='h6'>or SGD 88 per month</Typography>
      </Grid>
      <Grid item xs={2}>
        { subscribed ?
            <Button variant='contained' color='primary' fullWidth
              onClick={() => window.open(app.link, '_blank')}>
              Launch
            </Button>
          :
            <Button variant='contained' color='primary' fullWidth
              onClick={handleSubscribeEnterprise}>
              Subscribe
            </Button>
        }
        <br />
        <br />
        <Button variant='outlined' color='primary' fullWidth
          onClick={handleEnquiry}>
          Contact Sales
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='h6'>Features</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body1'>
          For Facilities Management Companies who require fully integrated end-to-end facade inspection services.
        </Typography>
        <ul>
          <li>UNLIMITED User Accounts *</li>
          <li>UNLIMITED FaultFinder AI Usage *</li>
          <li>UNLIMITED Inspections & Inspection Reports</li>
          <li>UNLIMITED Cloud Storage Capacity *</li>
        </ul>
        <Typography variant='body1'>9am - 5pm business hour email and whatsapp support.</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body1'>
          For Drone Operators and small businesses performing facade inspection services.
        </Typography>
        <ul>
          <li>UNLIMITED User Accounts *</li>
          <li>UNLIMITED FaultFinder AI Usage *</li>
          <li>5 Inspections & Inspection Reports per month</li>
          <li>1TB Cloud Storage Capacity</li>
        </ul>
        <Typography variant='body1'>9am - 5pm business hour email support.</Typography>
      </Grid>
      <Grid item xs={2}>
        &nbsp;
      </Grid>
      <Grid item xs={2}>
        <Button variant='outlined' onClick={handleBack}>
          Back
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Typography variant='body1'>
          <ExternalLink href='https://garuda.io/facilities'>
            See all features
          </ExternalLink>
        </Typography>
        <Typography variant='caption'>
          * Fair Use: Storage starts at 2TB, user accounts starts at 20, AI capacity starts with 1 GPU. Contact us to raise the cap when any of the limits are breached.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(AppFacilities)
