import { useState } from 'react'
import { toast } from 'react-toastify'
import { useCreateClientMutation } from '../../../../api/identity'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'

const CreateCredentialsDialog = ({
  onClose,
  companyId,
  teamMembers,
}) => {
  const [ createClient ] = useCreateClientMutation()

  const [ client, setClient ] = useState({ client_name: '' })
  const [ teamMember, setTeamMember] = useState('')
  const [ loading, setLoading ] = useState(false)

  const onChange = (e, key) => {
    const newClient = { ...client }
    newClient[key] = e.target.value
    setClient(newClient)
  }

  const handleMemberChange = (event) => {
    setTeamMember(event.target.value)
  }

  function handleCreate() {
    if (loading)
      return
    setLoading(true)

    const clientBody = {
      company_id: companyId,
      user_id: teamMember,
      grant_types: [
        'client_credentials'
      ],
      client_name: client.client_name,
    }
    createClient(clientBody)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status === 'success') {
        toast.success('Client created')
        onClose()
      } else {
        console.log('Failed to create client', fulfilled.data)
        toast.warn('Failed to create client')
        setLoading(false)
      }
    })
    .catch(rejected => {
      toast.error('Error creating client')
      console.log('Error creating client', rejected)
      setLoading(false)
    })
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='md' >
      <DialogTitle>
        Create Crednetials
      </DialogTitle>
      <DialogContent mt={3}>
        <Box
        component='form'
        noValidate
        autoComplete='off'>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id='label-member-select'>Team Member</InputLabel>
                  <Select
                    labelId='label-member-select'
                    id='member-select'
                    value={teamMember}
                    label='Team Member'
                    onChange={handleMemberChange}>
                    { teamMembers.map((member, i) => (
                      <MenuItem key={i} value={member.user_id}>
                        {`${member.name} (${member.email})`}
                      </MenuItem>
                    )) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <TextField
                    label='Client Name'
                    value={client.client_name}
                    onChange={e => onChange(e, 'client_name')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Box>
      </DialogContent>
      <DialogActions mt={2}>
        <Button onClick={handleCreate}>
          { loading ? 'Wait...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCredentialsDialog
