import { useState, useEffect } from 'react'
import {
  Accordion,
  Pagination,
} from '../../../components'
import {
  AccordionTitle,
  AccordionContent
} from '../../../components/Accordion'
import UserAppRoles from './UserAppRoles'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  column: {
    float: 'left',
    width: '32%',
  },
})

function UsersTable({ classes, users, canUpdate, onUpdate }) {
  const [ rowsPerPage, setRowsPerPage ] = useState(20)
  const [ page,        setPage        ] = useState(0)
  const [ rows,        setRows        ] = useState([])

  useEffect(() => {
    if (users.length > 0)
      setRows([ ...users ])
  }, [users])

  function handleReset() {
    setRows([ ...users ])
  }

  function handleUpdateRole(user, appKey = '', role = '') {
    const newUser = { ...user }

    if (!newUser.app_roles) // to transition legacy user objects with no app roles
      newUser.app_roles = {}
    else
      newUser.app_roles = { ...newUser.app_roles }

    if (!newUser.app_roles[appKey]) // to include new apps as they get created
      newUser.app_roles[appKey] = []

    if (newUser.app_roles[appKey].includes(role))
      newUser.app_roles[appKey] = newUser.app_roles[appKey].filter(r => r !== role)
    else
      newUser.app_roles[appKey] = [ ...newUser.app_roles[appKey], role ]

    setRows(rows.map(row => {
      if (row.user_id === user.user_id)
        return newUser
      else
        return row
    }))
  }

  function handleSaveUpdate(userId) {
    const user = rows.find(row => row.user_id === userId)
    onUpdate({
      user_id:   userId,
      app_roles: user.app_roles,
    })
  }

  return (
    <div>
      { rows.slice((page * rowsPerPage), ((page * rowsPerPage) + rowsPerPage)).map((row, index) => (
        <Accordion
          key={row.user_id}
          className={index === (rows.slice((page * rowsPerPage), ((page * rowsPerPage) + rowsPerPage)).length - 1) ? 'NoBorderRadius' : ''}>
          <AccordionTitle>
            <div className={classes.column}>{row.name}</div>
            <div className={classes.column}>{row.email}</div>
            <div className={classes.column}>{row.designation || row.role || '-'}</div>
          </AccordionTitle>
          <AccordionContent>
            <UserAppRoles
              user={row}
              canUpdate={canUpdate}
              onUpdateRole={handleUpdateRole}
              onReset={handleReset}
              onSaveUpdate={handleSaveUpdate}
            />
          </AccordionContent>
        </Accordion>
      ))}
      {rows.slice((page * rowsPerPage), ((page * rowsPerPage) + rowsPerPage)).length
        ? (<Pagination
            rowsPerPageOptions={[20, 100, 500]}
            rowsPerPage={rowsPerPage}
            rowsLength={rows.length}
            onPerPageChange={setRowsPerPage}
            page={page}
            onPageChange={(page) => setPage(page)}
          />)
        : 'No Data'}
      </div>
  )
}

export default withStyles(styles)(UsersTable)
