import { useSelector } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import { makeQueryString, standardHeaders } from './api-utils'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const ACCOUNTS_API = new URL(process.env.REACT_APP_ACCOUNTS_API).href
const ACCOUNTS_URL = new URL(process.env.REACT_APP_PUBLIC_ACCOUNTS_URL).href

export function getAccountsURL() {
  return new URL(ACCOUNTS_URL)
}

export function useAccessToken() {
  return useSelector(state => state?.oidc?.user?.access_token)
}

export function useDecodedAccessToken() {
  const token = useAccessToken()
  if (token) {
    const decoded = jwtDecode(token)
    const {
      plex: { company_id, company_status }
    } = decoded
    return {
      ...decoded,
      user_id: decoded.sub,
      company_id,
      company_status,
    }
  } else {
    return {
      user_id: '',
      company_id: '',
      company_status: '',
    }
  }
}
export function useMyUserId() {
  return useDecodedAccessToken().user_id
}
export function useMyCompanyId() {
  return useDecodedAccessToken().company_id
}
export function useMyCompanyStatus() {
  return useDecodedAccessToken().company_status
}
export function logout() {
  import('../utils/user-manager').then(userManager => {
    userManager.default.signoutRedirect()
  })
}

// TODO: Standardize calls (e.g. using makeQueryString, pass standard param object)
export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ACCOUNTS_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: [
    'AppRoles',
    'Users',
    'Companies',
    'Subscriptions',
    'Developers',
    'API Categories',
  ],
  endpoints: (builder) => ({
    // App Roles
    activeAppRoles: builder.query({
      query: () => `app_roles`,
      providesTags: ['AppRoles']
    }),
    appRolesSummary: builder.query({
      query: () => `app_roles/summary`,
      providesTags: ['AppRoles']
    }),
    // Users
    getUserById: builder.query({
      query: ({user_id}) => `users/${user_id}`,
      providesTags: ['Users'],
    }),
    getUsers: builder.query({
      query: (params) => (`/users${makeQueryString(params, [
        'company_id',
        'name',
        'username',
        'email',
        'status',
      ])}`),
      providesTags: ['Users'],
    }),
    createUser: builder.mutation({
      query: (newUser) => ({
        url: `/users`,
        method: 'POST',
        body: newUser,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/users/${user.user_id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    uploadAvatar: builder.mutation({
      query: ({user_id, file}) => {
        const formData = new FormData()
        formData.append('file', file)
        return {
          url: `/users/${user_id}/avatar`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Users'],
    }),
    changePassword: builder.mutation({
      query: ({ user_id, old_password, new_password }) => ({
        url: `/users/${user_id}/change-password`,
        method: 'PATCH',
        body: { old_password, new_password },
      })
    }),
    verifyPasswordResetToken: builder.query({
      query: ({ email, token }) => ({
        url: `/users/reset-password/verify-token`,
        method: 'PATCH',
        body: { email, token },
      })
    }),
    newPassword: builder.mutation({
      query: ({ email, token, new_password }) => ({
        url: `/users/reset-password/new-password-with-token`,
        method: 'PATCH',
        body: { email, token, new_password },
      })
    }),
    // Companies
    getCompanyById: builder.query({
      query: ({company_id}) => `companies/${company_id}`,
      providesTags: ['Companies'],
    }),
    verifyCompanyActivationToken: builder.query({
      query: ({ email, token }) => ({
        url: `/users/companies/activation/verify-token`,
        method: 'PATCH',
        body: { email, token },
      })
    }),
    activateCompany: builder.mutation({
      query: ({ email, token, admin }) => ({
        url: `/users/companies/activation/activate-company-with-token`,
        method: 'PATCH',
        body: { email, token, admin },
      })
    }),
    updateCompany: builder.mutation({
      query: ({ company_id, ...company }) => ({
        url: `/companies/${company_id}`,
        method: 'PATCH',
        body: company,
      }),
      invalidatesTags: ['Companies'],
    }),
    uploadCompanyAvatar: builder.mutation({
      query: ({company_id, file}) => {
        const formData = new FormData()
        formData.append('file', file)
        return {
          url: `/companies/${company_id}/avatar`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Companies'],
    }),
    // Subscriptions
    getSubscriptions: builder.query({
      query: (params) => (`/subscriptions${makeQueryString(params, [
        'company_id',
        'app_key',
      ])}`),
      providesTags: ['Subscriptions']
    }),

    // Developers
    getDevelopers: builder.query({
      query: (params) => (`/developers${makeQueryString(params, [
        'company_id',
      ])}`),
      providesTags: ['Developers']
    }),

    // API Categories
    getApiCategories: builder.query({
      query: () => '/api_categories',
      // TODO transform sort by order
      providesTags: ['API Categories'],
    }),

  }),
  
})

export const {
  useAppRolesSummaryQuery,
  useActiveAppRolesQuery,

  useGetUserByIdQuery,
  useLazyGetUserByUserIdQuery,

  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUploadAvatarMutation,
  useChangePasswordMutation,
  useVerifyPasswordResetTokenQuery,
  useNewPasswordMutation,

  useGetCompanyByIdQuery,
  useVerifyCompanyActivationTokenQuery,
  useActivateCompanyMutation,
  useUpdateCompanyMutation,
  useUploadCompanyAvatarMutation,

  useGetSubscriptionsQuery,

  useGetDevelopersQuery,
  useGetApiCategoriesQuery,
} = accountsApi
