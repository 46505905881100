import { red, yellow, blue, green, blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

// Accounts Web's Site Theme to follow Style Guide for Shared Services
const accountsTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main:  '#246FF1',
      light: '#709DFF',
      dark:  '#0045BD'
    },
    secondary: {
      main:  '#F1A624',
      light: '#FFD759',
      dark:  '#B97700'
    },
    error: {
      main: red.A700,
    },
    warning: {
      main: yellow.A700
    },
    info: {
      main: blue.A700
    },
    success: {
      main: green.A700
    },
    background: {
      main: '#424242',
    },
    bluegrey: {
      main:  blueGrey['500'],
      light: blueGrey['200'],
      dark:  blueGrey['800'],
      contrastText: '#fff'
    },
  }
})

export default accountsTheme
