import {
  Box,
  Typography,
} from '@mui/material'

function AuditLog() {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography>Audit Log is not enabled for your account.</Typography>
    </Box>
  )
}

export default AuditLog
